Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.postData="bx_block_custom_forms/other_life_goals"
exports.allValues="bx_block_custom_forms/show_other_life_goal"
exports.getAlcohol="bx_block_custom_forms/show_goal_response?category_name=Alcohol"
exports.getCannabis="bx_block_custom_forms/show_goal_response?category_name=Cannabis"
exports.cannabisPoint="bx_block_custom_forms/substance_goal_responses"
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.othersubstance="bx_block_custom_forms/goal_areas"
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.postOthers="bx_block_custom_forms/substance_goal_responses"
exports.endOthersPoint="bx_block_custom_forms/show_goal_response?category_name=Others"
exports.deleteApiMethodType = "DELETE";
exports.goalManagementURLEndPoint = "bx_block_goal_management/goal_managements";
exports.currentUserGoalURLEndPoint =
  "bx_block_goal_management/goal_managements/get_user_goals";
exports.getGroupURLEndpoint =
  "bx_block_goal_management/goal_managements/user_groups";
exports.saferUserStrategiesEndPoint = "bx_block_custom_forms/safer_user_strategies"
exports.loginURLEndPoint = "bx_block_login/logins";
exports.categoryShowPoint = "bx_block_categories/show_user_categories"
exports.loginEmail = "adminTeam@gmail.com";
exports.loginPassword = "123456";

exports.dateFormat = "MM/DD/YYYY";
exports.dateTimeFormat = "HH:mm A, MM/DD/YYYY";

exports.errorGetListMessage = "No goals present!";

exports.btnExampleTitle = "CLICK ME";
exports.alcoholHead = {
  first: "Ways to stay healthier when you drink because you’re worth it!",
  second: "Ways to make your drinking safer",
  third: "Ways to change how much you drink"
}

exports.cannabisHead = {
  first: "Ways to buffer the effects",
  second: "Ways to change how you use",
  third: "Ways to change how much you use"
}

exports.tobaccoHead = {
  first: "Ways to Reduce Risk",
  second: "",
  third: ""
}

exports.upperHead = {
  first: "Ways to be safer and healthier without changing use",
  second: "Ways to use more safely",
  third: "Ways to change how much you use"
}
// Customizable Area End