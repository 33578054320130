export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const arrow = require("../assets/arrow.png");
export const one = require("../assets/secondimage.png");
export const two = require("../assets/one.png");
export const bottomBannerImg = require("../assets/bottomBannerImg.png");
export const arrowright = require("../assets/arrowright.png");
export const imagealcohal = require("../assets/imagealcohal.png");
export const rightmovearrow = require("../assets/rightmovearrow.png");
export const backwardbutton=require("../assets/backwardbutton.png")
export const hamburgermenu = require("../assets/hamburgermenu.png");
export const cannabis = require("../assets/cannabis.png");
export const downers = require("../assets/downers.png");
export const uppers = require("../assets/uppers.png");
export const tabacco = require("../assets/tabacco.png");
export const others=  require("../assets/others.png");