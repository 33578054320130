export const image3 = require("../assets/secondimage.png");
export const arrow = require("../assets/arrow.png");
export const leftimage = require("../assets/leftimage.png");
export const rightimage = require("../assets/rightimage.png");
export const bottomBannerImg = require("../assets/bottomBannerImg.png");
export const menu = require("../assets/hamburgermenu.png");
export const edit = require("../assets/edit.png");
export const quotes = require("../assets/quotes.png");
export const three = require("../assets/three.png");
export const one = require("../assets/one.png")
export const time = require("../assets/time.png");
export const arrowright=require("../assets/arrowright.png")
export const calendar=require("../assets/calendar.png")
export const info = require("../assets/info.png");
export const button_add = require("../assets/button_add.png");
export const button_minus = require("../assets/button_minus.png");
export const loginArrow = require("../assets/loginArrow.png");
export const alcohol = require("../assets/alcohol.png");
export const cannabis = require("../assets/cannabis.png");
export const tobacco = require("../assets/tobacco.png");
export const uppers = require("../assets/uppers.png");
export const downers = require("../assets/downers.png");
export const others = require("../assets/others.png");
export const menuIcon = require("../assets/menuIcon.png");
export const textLabel = require("../assets/textlabel_.png");
export const substanceBottom = require("../assets/substanceBottom.png");
export const award_a6 = require("../assets/award.png")