import React from 'react';
// Customizable Area Start

import {
  Grid , Box,Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import HamburgerMenuController, {colors, configJSON} from "./HamburgerMenuController.web";
import { closeBtn } from './assets';


// Customizable Area End


export default class HamburgerMenu extends HamburgerMenuController {

 
  // Customizable Area Start
 
  // Customizable Area End

  render() {

    const {
      logOutBlockInnerStyle,
      logOutBlockContainorStyle,
      logOutBlock,
    } = this.props;
    
    return (
      // Customizable Area Start
      
      <Grid
        item
        style={{
          height: '100%',
          backgroundColor: '#0F4257',
          overflow: "hidden", 
          position: 'relative', 
        }}> 
    
         <Grid
          item
          xs={12}
          sx={{
            height: "88px",
            borderRadius: "16px",
            backgroundColor: "#0F4257",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              alignItems: "center",
              alignContent: "center",
              display: "flex",
              gap: "20px",
              "@media (max-width: 450px)": {
                gap: "10px",
              }
            }}
          >
            <Box
              sx={{
                fontSize: "clamp(25px, 5vw, 40px)",
                fontWeight: 300,
                color: "#ffffff",
                fontFamily: "Josefin Sans",
              }}
            >
              <Box
                sx={{
                  marginBottom: "4px",
                  color: "#EBFF00",
                  fontSize: "clamp(25px, 5vw, 40px)",
                  fontWeight: 700,
                  marginLeft: {
                    xs: '17px', 
                    md: '48px',
                  },
                  fontFamily: "Josefin Sans",
                  lineHeight: "40px",
                }}
                component="span"
                >
                  Well
                </Box>
              Spring
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Chromatica-Bold",
                mt: "3px",
                color: "#FFFFFF",
                fontSize: "clamp(14px, 2vw, 20px)",
                lineHeight: "20px",
                "@media (max-width: 590px)": {
                  width: "40%",
                  wordBreak: "break-word",
                }
              }}
            >
              Hey, {localStorage.getItem("username")}
            </Typography>
          </Box>
          <Box
            component="img"
            data-test-id="closebtn"
            onClick={()=>this.handleClose()}
              src={closeBtn.default}
              alt="Close"
            sx={{
              position: "absolute",
              right: "2.5%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              top: "50%",
              width:"clamp(2.2rem, 1.25rem + 3.3333vw, 3.125rem)",
              height:"clamp(2.2rem, 1.25rem + 3.3333vw, 3.125rem)",
            }}
          />
        </Grid>
        <StylesBoxHead sx={{
            position: "absolute",
            top: "50%",
            width:'auto',
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#ffffff",
            fontFamily: 'Josefin Sans',
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StylesBox data-test-id="creategoal" onClick={()=>this.CreateGoal()}component="span" sx={{ fontSize: "60px", cursor:'pointer',fontWeight: 900,color:this.state.createValue? "#EBFF00":"#ffffff",  textDecoration: this.state.createValue?"underline":'none', marginTop: "20px" }}>
            Create a Goal
          </StylesBox>
          <StylesBox data-test-id="reviewgoal" onClick={()=>this.ReviewGoals()} component="span" sx={{ paddingTop: '8px',cursor:'pointer', fontSize: "60px", fontWeight: 900,color:this.state.goalsValue? "#EBFF00":"#ffffff",textDecoration: this.state.goalsValue?"underline":'none', display: "inline-block", marginTop: "20px"}}>
            Review Goals
          </StylesBox>
          <StylesBox  data-test-id="safrergoal" onClick={()=>this.SaferUser()} component="span" sx={{ paddingTop: '8px', cursor:'pointer',fontSize: "60px", fontWeight: 900, display: "inline-block", marginTop: "20px" , color:this.state.saferValue? "#EBFF00":"#ffffff",textDecoration: this.state.saferValue?"underline":'none'}}>
            Safer Use Strategies
          </StylesBox>
          
          <StylesBox data-test-id="dash" onClick={()=>this.Dashboard()} component="span" sx={{ paddingTop: '8px', cursor:'pointer',fontSize: "60px", fontWeight: 900, display: "inline-block", marginTop: "20px" , color:this.state.dashboardValue? "#EBFF00":"#ffffff",textDecoration: this.state.dashboardValue?"underline":'none'}}>
            Dashboard
          </StylesBox>
          <StylesBox data-test-id="logout" onClick={()=>this.Logout()} component="span" sx={{ paddingTop: '8px', cursor:'pointer',fontSize: "60px", fontWeight: 900, color: "#ffffff", display: "inline-block", marginTop: "20px" }}>
            Logout
          </StylesBox>
        </StylesBoxHead>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StylesBox = styled(Box)({
  "@media (max-width: 400px)": {
    fontSize: "26px !important",
    width: "auto",
  },
  "@media (min-width: 401px) and (max-width: 600px)": {
    fontSize: "30px !important",
    width: "auto",
  },
  "@media (min-width: 601px) and (max-width: 900px)": {
    fontSize: "35px !important",
  },
  "@media (min-width: 901px) and (max-width: 1280px)": {
    fontSize: "55px !important",
  },
  "@media (min-width: 1300px) and (max-width: 1800px)": {
    fontSize: "60px !important",
  },
  "@media (min-width: 1801px)": {
    fontSize: "70px !important",
  },
})


const StylesBoxHead = styled(Box)({
  "@media (max-width: 400px)": {
    fontSize: "26px !important",
    padding: "0px",
    width: "300px",
    overflow: 'auto'
  },
  "@media (min-width: 401px) and (max-width: 500px)": {
    fontSize: "40px !important",
    padding: "0px",
    width: "300px",
    overflow: 'auto'
  }
})
// Customizable Area End
