export const Three_t = require("../assets/three.png");
export const Right_r = require("../assets/rightimage.png");
export const Left_l = require("../assets/leftimage.png");
export const arrow_a = require("../assets/arrow.png");
export const Menu_M = require("../assets/menuIcon.png");
export const award_a = require("../assets/award.png");
export const award_a1 = require("../assets/award.png");
export const award_a2 = require("../assets/award.png")
export const award_3 = require("../assets/award.png");
export const award_a4 = require("../assets/award.png");
export const award_a5 = require("../assets/award.png")
export const award_a6 = require("../assets/award.png")
export const ThumbBoy_b = require("../assets/ThumbBoy.png")

export const IMage_right = require("../assets/imageright.png")
export const awrad_Award = require("../assets/Vector.png")
export const arrow_Arrow_up = require("../assets/Vector1.png")
export const arrow_Arrow_arrow_down = require("../assets/Vector2.png")
export const plus_Plus = require("../assets/Vector3.png")
export const flower_flower = require("../assets/image_Blooming Flower.png")
export const hook_Hook = require("../assets/image_Cigarette.png")

export const Watch_Watch = require("../assets/watch.png")
export const pizza_pizza = require("../assets/pizza.png")
export const glass_glass = require("../assets/glass.png")
export const wave_wave = require("../assets/wave.png")
export const wava_right = require("../assets/waverightone.png")
export const tobacco = require("../assets/tobacco.png")
export const photo_photo = require("../assets/photo.png")
export const checkbox = require("../assets/checkbox.jpeg")
export const wave_user = require("../assets/wave_user.png")