export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgArrowLeft = require("../assets/arrow-left.png");
export const searchIcon = require("../assets/search-icon.png");
export const plusButton = require("../assets/selectedAdd.png");
export const viewImg = require("../assets/view.png");
export const dropdownIcon = require("../assets/dropdown-icon.png");
export const deleteIcon = require("../assets/delete-icon.png");
export const editIcon = require("../assets/edit-icon.png");
export const one = require("../assets/one.png");
export const calendarIcon = require("../assets/calendar-icon.png");
export const secondImage = require("../assets/secondimage.png");
export const calendarColorIcon = require("../assets/calendar-color-icon.png");
export const classIcon = require("../assets/class-icon.png");
export const clockIcon = require("../assets/clock-icon.png");
export const addButtonImg = require("../assets/addButton.png");
export const menuIconImg = require("../assets/menuIcon.png");
export const othersImg = require("../assets/others.png");
export const goalIcon = require("../assets/goal-item.png");
export const presentationIcon = require("../assets/presentation-icon.png");
export const uppertalkImg = require("../assets/uppertalk.png");
export const userIcon = require("../assets/user-icon.png");
export const background = require("../assets/background.png");
export const createGoalIcon = require("../assets/create-goal.png");
export const retrieveGoalIcon = require("../assets/retrieve-goal.png");
export const arrowFullRightIcon = require("../assets/arrow-full-right.png");

export const info = require("../assets/info.png");
export const backwardbutton = require("../assets/backwardbutton.png");
export const arrow = require("../assets/arrow.png");
export const three = require("../assets/three.png");

export const four = require("../assets/four.png");
export const bottomBannerImg=require('../assets/substanceBottom.png');
export const time=require('../assets/time.png');
export const calendar=require('../assets/calendars.png');
export const arrowright=require('../assets/arrowright.png');
export const rightmovearrow = require("../assets/rightmovearrow.png");
export const menuAdded = require("../assets/hamburgermenu.png");
export const uppersTabImg = require("../assets/uppers.png");
export const alcoholTabImg = require("../assets/alcohol.png");
export const downersTabImg = require("../assets/downers.png");
export const cannabisImg = require("../assets/cannabis.png");
export const tobaccoTabImg = require("../assets/tobacco.png");

const upperCoupleImg = require("../assets/upperCouple.png");
const UPF = require("../assets/upperFood.png");
const cannabisLawImg = require("../assets/cannabisLaw.png");
const beerImg = require("../assets/beer.png");
const alcoholImg = require("../assets/alcohol.png");
const tobaccoImg = require("../assets/tobacco.png");
const cannabisQuestionMarkImg = require("../assets/cannabisQuestionMark.png");
const cannabisFlowerImg = require("../assets/cannabisFlower.png");
const uppersImg = require("../assets/uppers.png");
const downersImg = require("../assets/downers.png");
const waterDropletImg = require("../assets/imageDroplet.png");
export const award_a6 = require("../assets/award.png")
export const alcoholData = {
  firstRow : [
    {
        id: 1,
        img: waterDropletImg,
        title: "Drink Water",
        why: "Staying hydrated reduces hangover effects.",
        how: "Drink water while you are drinking alcohol. Alternate between water and alcohol.",
        showArrow: true
    },
    {
      id: 2,
      img: alcoholImg,
        title: "Count your drinks",
        why: " Keeping track of the number and timing of your drinks helps you think through how much alcohol you need to get the effect you want.",
        how: " Keep your bottlecaps, tan tabs, etc. in your pocket.",
        showArrow: true
    },
    {
      id: 3,
      img: UPF,
        title: "Try to Eat",
        why: "Food eases the pace of alcohol entering the bloodstream.",
        how: "Try to eat before you start drinking and while you drink. Proteins (meat, cheese, beans) and carbs (bread, rice, pasta) are good choices.",
        showArrow: true
    },
    {
      id: 4,
      img: alcoholImg,
        title: "Take vitamins",
        why: "Drinking deprives you of nutrients. At high doses, it can harm brain health.",
        how: " Drink water, take vitamins, esp B-vitamins like folate, thiamine, B-12.",
        showArrow: true
    },
  ],
  secondRow: [
    {
      id: 5,
      img: beerImg,
        title: "Drink beer vs malt liquor",
        why: "If you drink stronger malt liquors, you might be getting more alcohol than you thought: one 24 oz. malt liquor = well over 3 regular 12 oz beers.",
        how: "Check the labels and try beer with 4-6% ABV instead.",
        showArrow: true
    },
    {
      id: 6,
      img: alcoholImg,
        title: "Space your drinks",
        why: "Keep the buzz going longer; avoid the not-so-good effects of drinking.",
        how: "Pace yourself. Sip your beer. Alternate between alcohol and water.",
        showArrow: true
    },
    {
      id: 7,
      img: alcoholImg,
        title: "Avoid mixing drugs",
        why: " Drinking and drugging at the same time can stress your heart and liver, and can lead to overdose.",
        how: "When you drink, try to avoid other drugs. Know the interaction effects.",
        showArrow: true
    },
    {
      id: 8,
      img: alcoholImg,
        title: "Drink in a safe place",
        why: "Drinking in unsafe places can lead to interpersonal or legal issues.",
        how: "If you can, avoid drinking heavily with people you don't trust. Try to drink in places where you feel more in control of your surrounding.",
        showArrow: true
    },
    {
      id: 10,
      img: alcoholImg,
        title: "Avoid non-beverage alcohol",
        why: " Mouthwash, aftershave, cooking wine, vanilla extract contain unpredictable amounts of alcohol and ingredients not meant to be drunk.",
        how: " Be sure any beverages consumed are meant for human consumption.",
        showArrow: true
    },
  ],
  thirdRow : [
    {
      id: 10,
      img: alcoholImg,
        title: "Less is more",
        why: "Most things people like about alcohol occur when buzzed, not drunk.",
        how: "Think of some ways you can limit your drinking, then pace your drinking to keep the buzz going on less drinks.",
        showArrow: true
    },
    {
      id: 11,
      img: alcoholImg,
        title: "Choose not to use",
        why: "Not drinking—even for a few hours—gives your liver, kidneys, and pancreas a rest and may avoid dependence or drinking in risky situations.",
        how: "Try a few hours of not drinking or introducing one non-drinking day a week. To stop altogether, medical detox might help.",
        showArrow: true
    },
    {
      id: 12,
      img: alcoholImg,
        title: "Avoid withdrawal",
        why: "Alcohol withdrawal (the shakes, seizures or DTs) can be life-threatening.",
        how: "If you want to stop drinking and you get more than a little shaky without a drink, medical detox is safest. Tapering slowly can also help relieve withdrawal symptoms; ask your doctor about anti-seizure meds.",
        showArrow: true
    },
  ]
}

export const cannabis = {
    firstRow : [
      {
        id: 13,
          img: cannabisFlowerImg,
          title: "Source wisely",
          why: "Cannabis products can contain contaminants, pesticides and fillers.",
          how: "When you can, buy from a licensed cannabis retailer. If you can't, buy from known and more trusted suppliers. ",
          showArrow: true
      },
      {
        id: 14,
        img: cannabisLawImg,
          title: "Understand the laws",
          why: "Legality and enforcement priority differs from place to place.",
          how: "Know laws and regulations where you are living, like rental agreements. Use in ways that will reduce your risk of arrest and prosecution.",
          showArrow: true
      },
      {
        id: 17,
        img: cannabisQuestionMarkImg,
          title: "Know your strain",
          why: "Educate yourself about the strain you are using and its possible effects.",
          how: "Indica-dominant strains can be more relaxing but sedating and sativa-dominant strains can be more energizing but anxiety-provoking. ",
          showArrow: true
      },
      {
        id: 18,
        img: cannabisFlowerImg,
          title: "Consider CBD",
          why: "CBD can counter some of the less desirable side effects of cannabis use, like anxiety or unwanted feelings of paranoia.",
          how: "Using CBD prior to THC use and/or using higher CBD/THC ratios.",
          showArrow: true
      },
    ],
    secondRow: [
      {
        id: 19,
        img: cannabisFlowerImg,
          title: "Use care with high THC",
          why: "High THC (> 85%) products like dabs pack an intense psychedelic experience. They can trigger psychosis (e.g., unwanted hallucinations, paranoia) & worsening of pre-existing psychological disorders, like depression or anxiety.",
          how: "Avoid high THC products, esp if you have a family history of psychosis and if you are younger, to avoid triggering psychosis",
          showArrow: true
      },

      {
        id: 20,
        img: cannabisFlowerImg,
          title: "Go low and slow",
          why: "It's harder to track exactly what dose you are getting when you vape or smoke, esp if you are sharing with others or using other substances. Edibles take longer to kick in—even by 1-2 hrs.",
          how: "After you take a hit, wait and see how you feel before doing more.",
          showArrow: true
      },

      {
        id: 21,
        img: cannabisFlowerImg,
          title: "Avoid mixing drugs",
          why: "Mixing can increase sedation, anxiety, memory gaps, overall impairment",
          how: "When you use cannabis, try to avoid other drugs or use with caution",
          showArrow: true
      },
     
      {
        id: 22,
        img: cannabisFlowerImg,
          title: "Find a safe mental and physical place",
          why: "In unsafe spaces, you can experience phychological and physical harm, legal issues, or interpersonal problems. Cannabis also impact judgement and reaction time.",
          how: "Avoid using with people you don't trust. Use where you feel more in control of your surroundings. Set positive mood with affirmations, music and xx. Never drive or operate heavy machinery when using.",
          showArrow: true
      },
      {
        id: 23,
        img: cannabisFlowerImg,
          title: "Avoid smoking",
          why: "Smoking and even vaping can irritate the lungs and can cause or worsen chronic lung disease like COPD or asthma.",
          how: "Edibles reduce impact on lungs. Vaping is generally safer than smoking. Consult your physician about personal risks with pre-existing conditions.",
          showArrow: true
      },
    ],
    thirdRow: [
      {
        id: 24,
        img: cannabisFlowerImg,
          title: "Less is more",
          why: "Most things people like about cannabis occur at lower intoxication levels",
          how: "Pace yourself to ride out your high while avoiding overconsumption or 'greening out'",
          showArrow: true
      },
      {
        id: 25,
        img: cannabisFlowerImg,
          title: "Choose not to use",
          why: " Daily use can lead to physical dependence and withdrawal. Using in risky situations—at work, driving, before medical procedures--can increase risk for physical harm. For some, not using at all is safest.",
          how: "Introducing non-use days can help avoid physical dependence and not using in certain situations, like prior to medical procedures, when driving or working, can reduce potential harm. Choosing not to use at all is ok, too! Figure out what's best for you.",
          showArrow: true
      },
    ]
  }

  export const tobaccoOrNicotine = {
    firstRow: [
      {
        id: 26,
        img: tobaccoImg,
        title: "Cutting down the number of cigarettes",
        why: "This will help you reduce the amount of carbon monoxide in your body. That will help you breathe better, and your body can get more oxygen.",
        how: "If it's hard to cut down, you can always replace some cigarettes during the day with safer nicotine products listed below.",
        showArrow: true
      },
      {
        id: 27,
        img: tobaccoImg,
        title: "Switch to a different type of tobacco product - smokeless tobacco.",
        why: "Smokeless tobacco is 85% safer than cigarettes because the chemicals are absorbed through the inside of your mouth instead of through smoke in your lungs. Although there is still a risk of developing some cancers, like oral cancer, it is much smaller than the risk of smoking. Certain kinds of smokeless tobacco, like moist snuff, dip, and snus, are a little safer than fire-cured chewing tobacco.",
        how: "Try to replace some cigarettes during the day with smokeless tobacco to reduce your smoking over time until you are ready to fully switch.",
        showArrow: true
      },
      {
        id: 28,
        img: tobaccoImg,
        title: "Switch to E-cigarettes and vaporizers",
        why: "E-cigarettes and vaporizers heat liquid that contains nicotine until it evaporates into steam and is inhaled. Although the long-term effects of e-cigarette and vaporizer use are not fully understood, they are 95% safer than smoking because they do not create the smoke that causes lung damage. Also, they do not contain high amounts of the cancer-causing agents found in tobacco smoke.",
        how: "Start by choosing a reputable brand of e-cigarette or vaporizer and gradually replace traditional cigarettes with these devices. Begin with a nicotine strength that matches your current smoking habits, and then slowly decrease the nicotine concentration over time to reduce dependence.",
        showArrow: true
      },
    ],
    secondRow: [],
    thirdRow: []
  }

  export const uppers = {
    firstRow: [
       {
        id: 30,
        img: upperCoupleImg,
          title: "Prepare for safer sex",
          why: "Stimulant use can lower your inhibitions and turn up your sex drive.",
          how: "Think ahead and carry condoms, dams, lube, and gloves with you. These barriers can prevent unwanted pregnancy and sexually transmitted infections like HIV and hepatitis C.",
          showArrow: true
      },

      {
        id: 31,
        img: uppersImg,
          title: "Test your drugs",
          why: "You can find it your drugs are cut with other drugs (e.g., fentanyl) or fillers (e.g., levamisole) that could harm you.",
          how: "Talk to providers about getting urine drug testing kits/testing liquids before you shoot. Check out dancesafe.org for kits. Some syringe service programs have more accurate testing.",
          showArrow: true
      },
      {
        id: 32,
        img: UPF,
          title: "Try to eat",
          why: "Stimulants can drain your body and dull your appetite. Food and water replenish these important nutrients to help you stay healthy.",
          how: "Eat nutritious foods before using. Pack snacks and water on the go. Avoid using over a long time. Let your body rest for at least a day after using.",
          showArrow: true
      },
      {
        id: 33,
        img: uppersImg,
          title: "Take care of your mouth",
          why: "Some Stimulants cause mouth dryness, sores, cracks, teeth clenching.",
          how: "Drink water to stay hydrated and chew gum to keep your mouth moist and your teeth from grinding. Brushing your teeth can helpcontrol increased bacteria due to dry mouth. Use chapstick to prevent lip and mouth cracking.",
          showArrow: true
      },
    ],

    secondRow: [
      {
        id: 34,
        img: uppersImg,
          title: "Choose safer ways of using",
          why: "Some ways of using drugs are less risky to your health.",
          how: "Taking drugs orally is safer than smoking which is safer than shooting. If you smoke crack, use your own mouthpiece for your pipe to avoid burns and germs.",
          showArrow: true
      },
      {
        id: 35,
        img: uppersImg,
          title: "Shoot safer",
          why: "Avoid overdose, blood-borne illness (HIV, hep C), bacterial infections.",
          how: "Pace yourself until you know the strength of your stash. Shooting into arm or hand veins is safer than hitting blind into your groin or neck. Rotate sites, shoot downstream if possible. Using new, clean needles and works can help prevent blood-borne illnesses and other infections.",
          showArrow: true
      },
      {
        id: 36,
        img: uppersImg,
          title: "Avoid mixing drugs",
          why: "Using stimulants and depressants (opioids, alcohol, benzos) at the same time can have unexpected effects, stress your heart, and lead to overdose.",
          how: "Try to stick to one drug at a time, especially when you are unsure of its strength or content.",
          showArrow: true
      },
      {
        id: 37,
        img: uppersImg,
          title: "Use with safe people with a safe place",
          why: "Heavy stimulent use can cause paranoia or aggression that can lead to fights, hassles and arrests. People can take advamtage of you when you're high",
          how: "Avoid using with people you don't know or trust. Use where you feel safe and in control of surroundings. Do not drive your bike when you're high.",
          showArrow: true
      },
    ],
    thirdRow: [
      {
        id: 38,
        img: uppersImg,
          title: "Less is more",
          why: "You can avoid overdosing or experiencing drugs' toxic effects.",
          how: "You can decide how you want to limit your use (buy only a certain amount or set a spending limit). Leave the rest of your money at home or in a safe place. You might ask a trusted friend to remind you of your limit.",
          showArrow: true
      },
      {
        id: 39,
        img: uppersImg,
          title: "Choose not to use",
          why: "Not using—even for a few hours or days—gives your body a rest and may help you avoid your body or mind becoming dependent on depressants.",
          how: "If you are not yet dependent and don't get strung out, try to not use for long periods of time and take days off from use every week to avoid getting hooked. Check in with your provider if you want to stop altogether.",
          showArrow: true
      },
      {
        id: 40,
        img: uppertalkImg,
          title: "Talk to a provider about withdrawal",
          why: "Alcohol/benzo withdrawal can be deadly. However, other drugs may have uncomfortable withdrawal symptoms that can harm vulnerable people.",
          how: "Talk to your provider if you are worried about withdrawal or if you have HIV or are pregnant. They may be able to help lessen symptoms.",
          showArrow: true
      },
    ]
  }

  export const downers = {
    firstRow: [
      {
        id: 41,
        img: downersImg,
          title: "Carry rescue drugs",
          why: "Opioids like heroin, fentanyl, and Oxys can lead to overdose",
          how: "Use the buddy system when you use opioids. Carry naloxone (Narcan) with you and give it to people who are near you when you use. It reverses overdose, but not for some drugs (Tranq, benzos). Check out nextdistro.org and neverusealone.com for info and support.",
          showArrow: true
      },
      {
        id: 42,
        img: downersImg,
          title: "Test your drugs",
          why: "You can be more aware if your drugs are cut with other drugs or fillers that could harm you. Note: Current methods are not fail-safe.",
          how: "Talk to providers about getting a urine drug testing kit and testing liquids before you use. For pills/powders, check out dancesafe.org for testing kits. Some syringe service programs have more accurate drug testing.",
          showArrow: true
      },
      {
        id: 43,
        img: downersImg,
          title: "Nurture your body",
          why: "Depressants can dull your appetite, can take a physical toll when used in larger amounts.",
          how: "Try to eat nutritious foods, water before/during use. For people who don't get strung out: Let your body rest for at least a day before using again; try to take as many days off as you used for.",
          showArrow: true
      },
      {
        id: 44,
        img: downersImg,
          title: "Take care of veins",
          why: "If you are shooting drugs, you can take care of your veins.",
          how: "Drink water, rotate injection sites, check out the Getting off manual",
          showArrow: true
      },
    ],
    secondRow: [
      {
        id: 45,
        img: downersImg,
          title: "Choose safer ways of using",
          why: "Some ways of using drugs are less risky to your health.",
          how: "Taking drugs orally is often safer than snorting or smoking which is safer than shooting. Snorting opioids has a greater risk of overdose than smoking.",
          showArrow: true
      },
      {
        id: 46,
        img: downersImg,
          title: "Shoot safer",
          why: "Avoid overdose, blood-borne illness (HIV, hep C), bacterial infections.",
          how: "Pace yourself until you know the strength of your stash. Shooting into bacterial infections arm or hand veins is safer than hitting blind into your groin or neck. Rotate sites, shoot downstream if possible. Using new, clean needles and works can help prevent blood-borne illnesses and other infections.",
          showArrow: true
      },
      {
        id: 47,
        img: downersImg,
          title: "Avoid mixing drugs",
          why: "Using different drugs at the same time can have unexpected effects, put stress on your heart, and lead to overdose.",
          how: "Know the risks of combining. Use one drug at a time if you're unsure.",
          showArrow: true
      },
      {
        id: 48,
        img: downersImg,
          title: "Use with safe people in safe place",
          why: "Depressants can caue sleepiness, confusion and lowered inhibitions. People can hurt or take advantage of you when you're high.",
          how: "Avoid using with people you don't know or trust. Use where you feel safe and in control of surroundings. Do not drive your bike when you are high.",
          showArrow: true
      },
    ],
    thirdRow: [
      {
        id: 49,
        img: downersImg,
          title: "Less is more",
          why: "You can avoid overdosing or experiencing drugs' toxic effects.",
          how: "You can decide how you want to limit your use (e.g., buy only a certain amount; set a spending limit). Leave the rest of your money at home or in a safe place. You might as a trusted friend to remind you of your limit.",
          showArrow: true
      },
      {
        id: 50,
        img: downersImg,
          title: "Choose not to use",
          why: "Not using—even for a few hours or days—gives your body a rest and may help you avoid your body or mind becoming dependent on depressants.",
          how: "If you are not yet dependent and don't get strung out, try to not use for long periods of time and take days off from use every week to avoid getting hooked. Check in with your provider if you want to stop altogether.",
          showArrow: true
      },
      {
        id: 51,
        img: uppertalkImg,
          title: "Talk to a provider about withdrawal",
          why: "Alcohol/benzo withdrawal can be life-threatening. Withdrawal from other depressants can make you feel sick. It can affect unborn babies, and people with certain illnesses more severely.",
          how: "Talk to your provider if you are cutting down or stopping. There are some effective medications to help prevent overdose and relieve craving and withdrawal.",
          showArrow: true
      },
    ]
  }