import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LandingPageController, {
    Props,
} from "./LandingPageController";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs from "dayjs";


export default class TimePickerData extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {onChange,onAccept,value,onClose}= this.props


        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker data-test-id="timepicker" sx={{'& .MuiPickersLayout-root': {display:'flex',flexDirection:'column'},}} onClose={onClose} value={value ? dayjs(value, "hh:mm A") : null} onAccept={onAccept}  onChange={onChange}/>
            </LocalizationProvider>
        );
    }
}

