Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "HamburgerMenu";
exports.labelBodyText = "HamburgerMenu Body";

exports.btnExampleTitle = "CLICK ME";
exports.Calender = "Calender"
exports.manageyourpersonalcalender = "Manage your personal calender"
exports.messageandCalls = "Message & Calls"
exports.talktoyourconnections = "Talk to your connections"
exports.companiespage = "Companies Page"
exports.viewyourcmpanypage = "View/Manage your companies page"
exports.referenceLabel = "References"
exports.manageReferences = "Manage references for job applications"
exports.settings = "Settings"
exports.settingNote = "Tune-in the pello"
exports.logout = "Logout",
  exports.myJobLabel = "My Jobs"
exports.viewyourjobApplications = "View your Job Applications"

exports.menu = "Menu"
exports.close = "Close"
// Customizable Area End