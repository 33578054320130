import React from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";

import AlcohalSubstanceController, {
    Props,
} from './AlcohalSubstanceController.web';
import {  arrowright } from './assets';




export const configJSON = require("./config.js");


export default class OtherSubstance extends AlcohalSubstanceController {
    constructor(props: Props) {
        super(props);

    }
    render() {
      
        return (
            <Box sx={{ position: "relative" }}>
               
                    <div>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                            Let us know what other substances you consumed, and how you need to work on this in 
                            terms of reduction?
                        </Typography>

                        <TextField
                            placeholder="Type here..."
                            InputProps={{
                                disableUnderline: false,
                                sx: {
                                    "&:after": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                    ".MuiInputBase-input": {
                                        paddingBottom: "5px",
                                    },
                                    "&:before": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                    "&:hover:not(.Mui-disabled):before": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                },
                            }}
                            value={this.state.valueOther}
                            multiline
                            onChange={this.handleChangeValue}
                            inputProps={{
                                style: {
                                    
                                    color: '#252525',
                                    fontSize: "16px",
                                    fontFamily: "Chromatica-Regular",
                                    fontWeight: 700,
                                },
                                sx: {
                                    "&::placeholder": {

                                        fontFamily: "Chromatica-Bold",
                                        fontSize: "16px",
                                        color: "#939393",
                                        fontWeight: 700,
                                    },
                                },
                            }}
                            sx={{
                               
                                width: "816px",
                                fontWeight: 700,
                                lineHeight: "24px",
                                mt: "25px",
                                color: "#939393",
                                fontSize: "16px",
                                fontFamily: "Chromatica-Regular",
                            }}
                           
                            fullWidth
                             rows={5}
                            variant="standard"
                        />
                        <style>
                            {`input::placeholder {color: #939393;font-family: 'Chromatica-Bold'; } `}
                        </style>
                        {this.state.valueOther?.length < 1 && this.state.OtherAddGoalButtonClick && <p style={{margin:'0', color: "#EB3E3E",
                            fontFamily: "Chromatica-Regular",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",marginTop:'5px'}}>Text field is required!</p>}
                        </div>           
                <Button  
                  data-test-id="buttonCannabis"     
                    onClick={()=>{this.CannabisPost()}}
                    variant="contained"
                    sx={{
                        padding: "10px",
                        borderRadius: "16px",
                        boxShadow: 'none',
                        background:
                            "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                        textTransform: "none",
                        backgroundColor: '#EFF8FB',
                        fontSize: "18px",
                        lineHeight: "20px",
                        marginTop: '50px',
                        marginBottom: '30px',
                        cursor: 'pointer',
                        width: '348px',
                        height: '56px',
                        fontWeight: 400,
                        fontFamily: 'Chromatica-Bold',

                    }}>
                    <span style={{ marginRight: '5px' }}>Add Goal</span>
                    <img src={arrowright.default} />
                </Button>
                <Box
                    sx={{
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: 4,
                    }}
                >
                </Box>
            </Box>
        )
    }
}
