import React from 'react';
import {
  Grid,
  Box,
  Typography
} from "@mui/material";
import GoalsController, {
  Props,
} from './GoalsController.web';
import { rightimage, leftimage, substanceBottom, menuIcon, one,image3, arrow } from './assets';



export const configJSON = require("./config.js");


export default class Goals extends GoalsController {
  constructor(props: Props) {
    super(props);

  }
  render() {
    return (
      <Grid
        item
        data-test-id= "modal-others"
        id="modal-others"
        style={{
          overflowY:'scroll',
          width:'100%',
          backgroundColor: "#F0F0F0",
          padding: "10px",
        }}
      >
        <Grid
          item
          style={{
            height: '88px',
            backgroundColor: '#0F4257',
            borderRadius: '16px',
            position:'relative'
          }}
        >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                alignContent: "center",
                gap: "20px",
                "@media (max-width: 450px)": {
                  gap: "10px",
                }
              }}
            >
              <Box
                sx={{
                  fontFamily: "Josefin Sans",
                  fontSize: "clamp(25px, 5vw, 40px)",
                  color: "#ffffff",
                  fontWeight: 300,
                }}
              >
                <Box
               
                sx={{
                  marginBottom: "4px",
                  fontWeight: 700,
                  color: "#EBFF00",
                  fontSize: "clamp(25px, 5vw, 40px)",
                  marginLeft: {
                    xs: '17px', 
                    md: '48px',
                  },
                  fontFamily: "Josefin Sans",
                  lineHeight: "40px",
                }}
                component="span"
                >
                  Well
                </Box>
                Spring
              </Box>
              <Typography
                sx={{
                  fontFamily: "Chromatica-Bold",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                  mt: "3px",
                  fontSize: "clamp(14px, 2vw, 20px)",
                  "@media (max-width: 590px)": {
                    width: "40%",
                    wordBreak: "break-word",
                  }
                }}
              >
                Hey, {this.state.userName}
              </Typography>
            </Box>
            <Box
              onClick={() => { this.hamburgerMenu() }}
              component="img"
              src={menuIcon.default}
              alt="menuIcon"
              sx={{
                position: "absolute",
                top: "50%",
                right: "2.5%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
              }}
            />
        </Grid>
        <Grid
          item
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '15px',
            width: '100%',
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          {localStorage.getItem('NewUseFound') =='Yes'?(
            <Box 
            onClick={() => this.introductionPage()}
             sx={{ paddingTop: '30px', cursor: 'pointer' }}
              data-test-id="back-btn">
            <Box           
              alt="Back Arrow"
              sx={{ position: "absolute", paddingTop: '6px', left:{sm: 50, xs:20 }}}
              component="img"
              src={arrow.default}
           />
            <Typography
              sx={{
                position: "absolute",            
                fontFamily: 'Chromatica-Bold',
                left:{sm: 70, xs:40 },
                cursor: "pointer",
                color:'#252525',
              }}
            >
              Back
            </Typography></Box>):''}

          <Box sx={{ position: "relative",  height:'auto',
                    minHeight:'89vh',top: 20,marginLeft: {
                      sm: '50px',
                      xs:'20px' },
                      marginRight:{
                          sm: '50px',
                          xs:'20px' 
                      }, }}>
            <Box
              component="img"
              src={localStorage.getItem('NewUseFound') =='Yes'? image3.default:one.default}
              alt="Logo Top Left"
              sx={{
                width: localStorage.getItem('NewUseFound') === 'Yes' ? '120px' : '90px',
                height: '180px',
                position: 'relative',
                left: '13px'
              }}
              
            />
            <Typography
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                marginTop: '109px',
                fontFamily: 'Josefin Sans',
                fontWeight: '900',
                fontSize: '32px',
                color: '#00A7D1'
              }}
            >
              Your Goals
            </Typography>
            <Typography sx={{
              fontFamily: 'Chromatica-Regular',
              fontSize: '16px',
              color: '#252525',
              marginBottom: '20px'
            }}
            >
              What do you want to see happen for yourself? Some people call this goals, intentions, or visions.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                width: '100%',
                flexDirection: {
                  xs: 'column', 
                  sm: 'row',    
                },
                marginBottom: '100px',
              }}
            >
              <Box
                component="img"
                onClick={() => this.substancePage()}
                src={rightimage.default}
                alt="Right Image"
                sx={{
                  zIndex: 100,
                  width: {
                    sm: '50%',    
                    xs: '100%', 
                  },
                  objectFit: 'contain',
                  height: 'auto',
                  cursor: 'pointer',
                  paddingTop: '20px',
                }}
              />

              <Box
                component="img"
                onClick={() => this.OtherGoals()}
                src={leftimage.default}
                alt="Left Image"
                sx={{
                  zIndex: 100,
                  width: {
                    xs: '100%', 
                    sm: '50%',    
                  },
                  height: 'auto',
                  objectFit: 'contain',
                  paddingTop: '20px',
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box
              sx={{
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                px: 4,
              }}
            >
             <Box
                  component="img"  
                  sx={{ 
                    width: '100%',
                    position: "absolute",
                    bottom: 19,
                    right: "-50px",
                    height: 'auto',
                    maxWidth: '600px',
                    display:{
                      xs:'none',
                      md:'block'
                  }
                  }}
                  alt="bottomSection"
                  src={substanceBottom.default}
                />
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

