import React from "react";
import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController.web";

export default class VisualAnalytic extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <></>
      // Customizable Area End
    );
  }
}


// Customizable Area End
