import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LandingPageController, {
    Props,
} from "./LandingPageController";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs from 'dayjs';

export default class TimeOtherGoals extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {onChange,onAccept,value,onClose}= this.props
        /* istanbul ignore next */
        

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker  data-test-id="timepickerOther" onClose={() => onClose(value)} value={value ? dayjs(value, "hh:mm A") : null} onAccept={(value)=>onAccept(value)}  onChange={(newDate)=>onChange(newDate)}/>
            </LocalizationProvider>
        );
    }
}

