import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LandingPageController, {
    Props,
} from "./LandingPageController";
import dayjs from "dayjs";


export default class DateOtherGoals extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {onChange,value}= this.props

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value ? 
                    dayjs(value) : 
                    null} 
                data-test-id="timepicker"
                 label=""
                 minDate={dayjs()}
                 onChange={(newDate)=>onChange(newDate)}
                    slotProps={{
                        textField: {
                            placeholder: 'Select other date',
                            InputProps: {
                                style: {
                                    width: '325px', 
                                },
                            },
                            sx: {
                                "& .MuiOutlinedInput-root": {
                                    fontFamily: 'Chromatica-Regular',
                                    "& input": {
                                        fontFamily: 'Chromatica-Regular',
                                    },
                                 
                                    "&.Mui-focused fieldset": {
                                        borderColor: '#00A7D1',
                                    },
                                    "& fieldset": {
                                        borderColor: '#00A7D1',
                                    },
                                    "&:hover fieldset": {
                                        borderColor: '#00A7D1',
                                    },
                                },
                                "& .MuiInputBase-input::placeholder": {
                                    fontFamily: 'Chromatica-Regular',
                                },
                            },
                        },
                        
                        day: {
                            sx: {
                               
                                "&.MuiPickersDay-dayWithMargin": {
                                    "&:focus": {
                                        backgroundColor: '#00A7D1', 
                                    },
                                },
                                "&.Mui-selected": {
                                    backgroundColor: '#00A7D1', 
                                },
                                "&.Mui-selected:hover": {
                                    backgroundColor: '#0086A3',
                                },
                            },
                        },
                    }}
                />
            </LocalizationProvider>
        );
    }
}

