import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
import dayjs from "dayjs";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    updateOther:any,
    updateAns:any,
    safeValueOther:any,
    existingData: any,
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    checkedItems: any,
    checkedIte: any,
    gridItems: any,
    scrollOffset: number,
    currDate: NumberIndexedObject;
    newDateData: string,
    timeSet: any,
    openCalender: string,
    openTime: boolean,
    formattedDateInter:string,
    OthersResponse:string,
    valueOther:string,
    OtherAddGoalButtonClick:boolean
    selectedDate: string | Date,
    options: any,
    optionsDate:any,
    drinkCount: number,
    drinkCountSecond: number,
    drinkCountSecondOften: number,
    drinkCountOften: number,
    selectedValue: any,
    selectedValueOption: any,
    selectedValueCalendar:any,
    AlcohalButtonCalled:boolean,
    OtherValue:boolean,
    dateErrorMessage:string,
    allowAlcohalPostApiCall:boolean,
    hideDateError: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}
interface NumberIndexedObject {
    [key: string]: string;
}

export default class AlcohalSUbstanceController extends BlockComponent<
    Props,
    S,
    SS
> {
    getCannabisData:string="";
    getAlcohal:string="";
    postCannabis:string=""
    postAlcoholData:string="";
    putSubstanceAlcoholApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleSaferValueAlcohol = this.handleSaferValueAlcohol.bind(this);
        this.handleChangeOther = this.handleChangeOther.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            scrollOffset: 0,
            gridItems: [],
            checkedItems: {},
            checkedIte: {},
            options: [],
            optionsDate:[],
            newDateData: "",
            timeSet: '',
            currDate: {},
            openCalender: "",
            openTime: false,
            formattedDateInter:'',
            OthersResponse:'',
            valueOther:'',
            OtherAddGoalButtonClick:false,
            selectedDate: "",
            selectedValue: 'Reduce how much',
            selectedValueOption: '',
            selectedValueCalendar:'',
            drinkCount: 0,
            drinkCountSecond: 0,
            drinkCountSecondOften: 0,
            drinkCountOften: 0,
            AlcohalButtonCalled:false,
            OtherValue:false,
            dateErrorMessage:'',
            allowAlcohalPostApiCall:false,
            hideDateError: true,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.generateOptions();
        this.generateCalendar();
        this.setState({
          selectedValue: this.props.existingData.selectedValue,
          selectedValueOption: this.props.existingData.selectedValueOption,
          drinkCount: this.props.existingData.drinkCount,
          drinkCountSecond: this.props.existingData.drinkCountSecond,
          drinkCountSecondOften: this.props.existingData.drinkCountOftenSecond,
          drinkCountOften: this.props.existingData.drinkCountOften,
          formattedDateInter: this.props.existingData.selectedDate,
          selectedDate: moment(this.props.existingData.selectedDate).format("MM/DD/YYYY"),
          OthersResponse: this.props.existingData.OthersResponse,
        }, () => localStorage.setItem('selectedSubValue',this.state.selectedValue));
      }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (isResponseMessage) {
          if (this.getCannabisData === responseData && !responseJson.errors) {
            if(responseJson.responses){
              this.setState({valueOther:responseJson?.responses?.answer})
            }
          }
          else  if (this.getAlcohal === responseData && !responseJson?.errors) {
            this.getAlcoholApiResponse(responseJson)
           }
           else if(this.postAlcoholData === responseData && !responseJson?.errors){
            const selectedCardId = responseJson.substance_goal_response.id;
            setStorageData("selectedCardId", selectedCardId);
            setStorageData("newData", true);
            localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
            this.props.updateAns(responseJson?.message);
           }
           else if(this.putSubstanceAlcoholApiCallId === responseData && !responseJson?.errors){
            this.props.updateAns(responseJson?.message);
           }
           else if(this.postCannabis === responseData && !responseJson?.errors){
             /* istanbul ignore next */
            localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
            this.props.updateOther(responseJson?.message);
           }
        };    
      }
      getAlcoholApiResponse = (responseJson: any) => {
        const CannabisResponses = responseJson?.responses;

        this.setState({
          formattedDateInter:CannabisResponses?.stop_completely?.answer2,
          drinkCountOften: CannabisResponses?.reduce_how_often?.answer1 || 0,
          drinkCountSecondOften: CannabisResponses?.reduce_how_often?.answer2 || 0,
          OthersResponse: CannabisResponses?.others?.answer1 || '',
          selectedValueOption: CannabisResponses?.stop_completely?.answer1 || '',
          drinkCount: CannabisResponses?.reduce_how_much?.answer1 || 0,
          drinkCountSecond: CannabisResponses?.reduce_how_much?.answer2 || 0,
        });
  
        if (CannabisResponses?.reduce_how_much) {
          this.setState({ selectedValue: 'Reduce how much' });
        } else if (CannabisResponses?.reduce_how_often) {
          this.setState({ selectedValue: 'Reduce how often' });
        } else if (CannabisResponses?.stop_completely) {
          this.setState({ selectedValue: 'Stop completely' });
        } else if (CannabisResponses?.others) {
          this.setState({ selectedValue: 'Other' });
        }
       else if(CannabisResponses?.safer_healthier){
        this.setState({ selectedValue: 'Safer/healthier' });
       }
      };
      
      AlcohalPostAPiCall = async () => {
        localStorage.setItem("selectedHeading",'Alcohol')
        const selectedCardId = await getStorageData("selectedCardId");
        if (this.state.allowAlcohalPostApiCall) {
          let data = localStorage.getItem("token") || "{}";
          const dataSend = {
            "Content-Type": configJSON.validationApiContentType,
            token: data,
          };
          let responses = {};

          if (this.state.selectedValue === "Reduce how much") {
            localStorage.setItem("value1",String(this.state.drinkCount))
            localStorage.setItem("value2",String(this.state.drinkCountSecond))
            responses = {
              reduce_how_much: {
                question1:
                  "Over the last week, how many standard drinks did you have on a typical drinking day?",
                answer1: this.state.drinkCount,
                question2:
                  "What would you like to reduce that amount to this week?",
                answer2: this.state.drinkCountSecond,
              },
            };
          } else if (this.state.selectedValue === "Reduce how often") {
            localStorage.setItem("value1",String(this.state.drinkCountOften))
            localStorage.setItem("value2",String(this.state.drinkCountSecondOften))
            responses = {
              reduce_how_often: {
                question1:
                  "Out of the past 7 days, how many days did you drink alcohol?",
                answer1: this.state.drinkCountOften,
                question2:
                  "Out of the NEXT 7 days, how many days do you want to drink alcohol?",
                answer2: this.state.drinkCountSecondOften,
              },
            };
          } else if (this.state.selectedValue === "Stop completely") {
            localStorage.setItem("value1",String(this.state.formattedDateInter))
            localStorage.setItem("value2",'')
            responses = {
              stop_completely: {
                question1:
                  "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from drinking at this point?",
                answer1: this.state.selectedValueOption,
                question2:
                  "Way to commit to taking a break from drinking! What day this week do you want to start?",
                answer2: this.state.formattedDateInter,
              },
            };
          } else if (this.state.selectedValue === "Other") {
            localStorage.setItem("value1",String(this.state.OthersResponse))
            localStorage.setItem("value2",'')
            responses = {
              others: {
                question1: "What do you want to see happen with your drinking?",
                answer1: this.state.OthersResponse,
              },
            };
          } else if (this.state.selectedValue === "Safer/healthier") {
            localStorage.setItem("value1",'')
            localStorage.setItem("value2",'')
            responses = {
              safer_healthier: {},
            };
          }

          const httpBody = {
            substance_goal_response: {
              category_name: "Alcohol",
              responses: responses,
            },
          };

          if(selectedCardId) {
            const postAlcoholDataSave = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.putSubstanceAlcoholApiCallId = postAlcoholDataSave.messageId;
  
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
            );
  
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              dataSend
            );
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              "PUT"
            );
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
            runEngine.sendMessage(postAlcoholDataSave.id, postAlcoholDataSave);
          } else {
            const postAlcoholDataSave = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.postAlcoholData = postAlcoholDataSave.messageId;
  
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.cannabisPoint}`
            );
  
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              dataSend
            );
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.postApiMethodType
            );
            postAlcoholDataSave.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
            runEngine.sendMessage(postAlcoholDataSave.id, postAlcoholDataSave);
            removeStorageData("selectedCardId");
            removeStorageData("newData");
            removeStorageData("firstSelectedCardId");
            removeStorageData("firstNewData");
          }
        }
      }

      checkAllAlcohalRequired = () => {

        const {selectedValue,OthersResponse} = this.state
        let allowAlcohalPostApiCall = false;
        const isTextValid = (textValue: string | any[]) => textValue && textValue.length >= 1;
        const isValidText = /^[^a-zA-Z]*$/.test(this.state.OthersResponse || '');

      switch (selectedValue) {  
        case 'Reduce how much':
          if(this.state.drinkCount > 0 && this.state.drinkCount >= this.state.drinkCountSecond) {
            allowAlcohalPostApiCall = true;
          }
          this.setState({ allowAlcohalPostApiCall }, () => {
            this.AlcohalPostAPiCall();
          });
          break;
          case 'Reduce how often':
          if(this.state.drinkCountOften > 0 && this.state.drinkCountOften >= this.state.drinkCountSecondOften) {
            allowAlcohalPostApiCall = true;
          }
          this.setState({ allowAlcohalPostApiCall }, () => {
            this.AlcohalPostAPiCall();
          });
          break;
        case 'Stop completely':
        
          if (this.state.selectedValueOption?.length > 1 && this.state.formattedDateInter.length > 3) {
            allowAlcohalPostApiCall = true;
            this.setState({ hideDateError: true });
          } 
          this.setState({ allowAlcohalPostApiCall }, () => {
            this.AlcohalPostAPiCall();
          });
          break;
        case 'Other':
          if (isTextValid(OthersResponse) && !isValidText) {
            allowAlcohalPostApiCall = true;
          }
          this.setState({ allowAlcohalPostApiCall }, () => {
            this.AlcohalPostAPiCall();
          });
          break;
        default :
        allowAlcohalPostApiCall = true;
        this.setState({ allowAlcohalPostApiCall }, () => {
          this.AlcohalPostAPiCall();
        });
      }     
      }


      SaveAlcohalData = async () => {
        this.setState({
          AlcohalButtonCalled:true,
          hideDateError: false,
        })
        this.checkAllAlcohalRequired(); 
    }
    getAlcohalData = () => {
        let data = localStorage.getItem("token") || "{}";
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getAlcohal = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAlcohol
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      };
    formatDate(date:any) {
        const dateMoment = moment(date); 
        const formattedDate = dateMoment.format('ddd, MMMM D'); 
        const isToday = dateMoment.isSame(moment(), 'day'); 
        return isToday ? formattedDate.replace(',', '') : formattedDate;
    }
    
    generateCalendar() {
      const today = new Date();
      const optionsDate = [
          { 
              value: 'today', 
              label: `Today, ${this.formatDate(today)}`, 
              date: this.formatDateYYYYMMDD(today) 
          },];
      for (let i = 1; i <= 4; i++) {
          const nextValueDate = new Date(today);
          nextValueDate.setDate(today.getDate() + i);
          optionsDate.push({
              value: `nextDay${i}`,
              label: `${this.formatDate(nextValueDate)}`,
              date: this.formatDateYYYYMMDD(nextValueDate) // Add the new field here
          });
      }
      optionsDate.push({ value: 'other', label: 'Other Day', date: '' }); // Add date field for 'Other Day'
      this.setState({ optionsDate });
    }
    formatDateYYYYMMDD(date: any) {
      if (!date) {
        return '';
      }
      const validDate = new Date(date);
      if (isNaN(validDate.getTime())) {
        return 'Invalid date';
      }
      
      const year = validDate.getFullYear();
      const month = String(validDate.getMonth() + 1).padStart(2, '0');
      const day = String(validDate.getDate()).padStart(2, '0');
    
      return `${month}-${day}-${year}`;
    }
    
  handleChangeDates = (event: any) => {
    this.setState({ formattedDateInter: event.date, selectedDate: "", hideDateError: event.date === "" ? true : false });
  };
  handleDataCalendar = (newDate: any) => {
    const formattedDate = dayjs(newDate.$d).format('MM-DD-YYYY');
    this.setState({ selectedDate: formattedDate })
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  closePopUp() {
    this.setState({
      openCalender: ""
    });
  }
  openPopUp(key: number) {
    this.setState({
      openCalender: key.toString(),
      dateErrorMessage: "",
    });
  }
  confirmCal() {
    const currentDate = dayjs(); 
    const selectedDate = this.state.selectedDate; 
  
    if (selectedDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (dayjs(selectedDate).isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return; 
    }
  
    this.setState({
      formattedDateInter: moment(selectedDate).format("MM-DD-YYYY"),
      OtherValue: true,
      openCalender: "",
      dateErrorMessage: "",
    });
  }
  handleIncrement = () => {
    this.setState((prevState) => ({drinkCount: prevState.drinkCount + 1}));
};
    handleChangeOther(event:any){
        this.setState({OthersResponse:event?.target?.value?.replace(/^\s+/, ''),})
      }
      handleIncrementOften = () => {
        this.setState((prevState) => {
          if (prevState.drinkCountOften < 7) {
            return { drinkCountOften: prevState.drinkCountOften + 1 };
          }
          return null;
        })
      };
   
    handleIncrementSecond = () => {
        this.setState((earlierState) => ({drinkCountSecond: earlierState.drinkCountSecond + 1
        }));
    };

   
   
    handleInputChangeOften = (event: any) => {
      const value = Number(event.target.value);
      if (!isNaN(value) && value >= 0 && value <= 7) {
        this.setState({ drinkCountOften: value });
      }
    };
    handleInputChangeSecondOften = (event: any) => {
      const value = Number(event.target.value);
      if (!isNaN(value) && value >= 0 && value <= 7) {
        this.setState({ drinkCountSecondOften: value });
      }
    };
    handleIncrementSecondOften = () => {
      this.setState((earlierState) => {
        if (earlierState.drinkCountSecondOften < 7) {
          return { drinkCountSecondOften: earlierState.drinkCountSecondOften + 1 };
        }
        return null;
      });
  };
  handleDecrementOften = () => {
    this.setState((prevState) => ({drinkCountOften: prevState.drinkCountOften > 0 ? prevState.drinkCountOften - 1 : 0
    }));
};
    handleInputChange = (event: any) => {
      const value = event.target.value; if (!isNaN(value) && value >= 0) {this.setState({ drinkCount: Number(value) });
        }
    };
    handleInputChangeSecond = (event: any) => {
        const answer = event.target.value;
        if (!isNaN(answer) && answer >= 0) {
            this.setState({ drinkCountSecond: Number(answer) });
        }
    };
   
    handleDecrementSecondOften = () => {
        this.setState((prevState) => ({
            drinkCountSecondOften: prevState.drinkCountSecondOften > 0 ? prevState.drinkCountSecondOften - 1 : 0
        }));
    };
    handleDecrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount > 0 ? prevState.drinkCount - 1 : 0
        }));
    };
    handleDecrementSecond = () => {
        this.setState((prevState) => ({
            drinkCountSecond: prevState.drinkCountSecond > 0 ? prevState.drinkCountSecond - 1 : 0
        }));
    };
    handleChangeValue(event:any){
      this.setState({valueOther:event?.target?.value})
    }
    CannabisPost= () => {
      this.setState({
        OtherAddGoalButtonClick:true
      })
      if(this.state.valueOther?.length >= 1){
        let data = localStorage.getItem("token") || "{}"
        const dataSend = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const httpBody = {
            substance_goal_response: {
                category_name: "Others",
                responses: {
                    answer:this.state.valueOther
                }
            }
        };
        const postCannabisData = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postCannabis = postCannabisData.messageId;
    
        postCannabisData.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.postOthers}`
        );
    
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          dataSend
        );
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        runEngine.sendMessage(postCannabisData.id, postCannabisData);
      }
    }
      
    CannabisGet = () => {
        let data = localStorage.getItem("token") || "{}";
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCannabisData = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.endOthersPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      };
    generateOptions() {

        const options = [
            { value: 'Reduce how much', label: `Reduce how much` },
        ];
        options.push({ value: 'Reduce how often', label: 'Reduce how often' });
     
        options.push({ value: 'Stop completely', label: 'Stop completely' });
        options.push({ value: 'Other', label: 'Other' });
        this.setState({ options });
    }
    handleChange = (event: any) => { 
      this.setState({AlcohalButtonCalled:false})
      localStorage.setItem("selectedSubValue",event.target.value)
      this.setState({
        selectedValue: event.target.value,
        drinkCountOften: 0,
        formattedDateInter:'no',
        drinkCountSecondOften: 0,
        OthersResponse: '',
        selectedValueOption: 0,
        drinkCount: 0,
        drinkCountSecond: 0,
      })
    }
    handleChangeOption = (event: any) => {
        this.setState(prevState => {
          return {
            selectedValueOption: event.target.value,
            formattedDateInter: prevState.selectedValueOption === "No" ? "no" : prevState.formattedDateInter,
            hideDateError: prevState.selectedValueOption === "No" ? true : false,
          }
        });
    };
    handleSaferValueAlcohol=()=>{
      if(typeof this.props.safeValueOther === 'function'){
      this.props.safeValueOther('Yes')}
    }
     handleChangeCalendar= (event: any) => {
        this.setState({ selectedValueCalendar: event.target.value });
    };

}
