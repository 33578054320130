
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeBtn = require("../assets/close.png");
export const icons = {
    calender: 'date-range',
    comment_dots: 'comments',
    business: 'business',
    people: 'people',
    settings: 'settings',
    navigate_next: 'navigate-next',
    logout: 'input',
    chevron_left: 'chevron-left',
    chevron_right: 'chevron-right',
    search: 'search',
    playlist_add: 'playlist-add',
    edit_calendar: 'event-available',
    videocam: 'videocam',
    location_on: 'location-on',
    more_vert: 'more-vert',
    home: 'home',
    call: 'call',
    account_box: 'account-box',
    email: 'email',
    work: 'work',
    plus: 'add',
    photo_camera: 'photo-camera',
    notifications: 'notifications',
    lock: 'lock',
    border_color: 'border-color',
    delete: 'delete',
    dollar: 'dollar',
    calendar: 'calendar',
  
    phone: 'phone',
    contact: 'contacts',
    back: 'chevron-left',
    edit: 'edit',
    check: 'check',
    share: 'share',
  
    close: 'close',
  
    date_range: 'date-range',
  
    add_circle_outline: 'add-circle-outline',
    play_arrow: 'play-arrow',
    cached: 'cached',
    loop: 'loop',
    flash_off: 'flash-off',
    flash_on: 'flash-on',
  
    expand_more: 'expand-more'
  };

