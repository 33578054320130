// Customizable Area Start
import { styled } from "@mui/material/styles";
import React from "react";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import {
    image,
    notation,
    bottomBannerImg,
    topBannerImg,
    image1,
    image2,
    image3
} from "./assets";
import LandingPageController, {
    Props,
} from "./LandingPageController";


export default class WellSpring extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
   
    render() {
      
        return (
            <Grid
                container
                sx={{ 
                    backgroundColor: "#F0F0F0",
                    padding: "10px",
                    overflowY:'scroll',
                    overflowX:'hidden',
                    height:'100%',
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Grid
                    item
                    sx={{
                        position: "relative",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        flexBasis: "49.3333%",
                        maxWidth: "49.3333%",
                        "@media (max-width: 1025px)": {
                            flexBasis: "100%",
                            maxWidth: "100%",
                        },
                    }}
                >
                    <Box
                        sx={{
                            px: 4,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            component="img"
                            alt="Logo"
                            src={topBannerImg.default}
                            sx={{ position: "absolute", top: 0, left: 0 }}
                        />
                        <Box
                            component="img"
                            alt="Logo"
                            src={bottomBannerImg.default}
                            sx={{ position: "absolute", bottom: 0, right: 0 }}
                        />

                        <Box
                            sx={{
                                zIndex: 1,
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <TypographyWellHeading
                                variant="h3"
                                sx={{
                                    fontSize:'40px',
                                    color: "#00A7D1",
                                    
                                    lineHeight: "30px",
                                    paddingTop: '60px',
                                    mb: 2,
                                    textAlign: "left",
                                    fontFamily: 'Josefin Sans',
                                    fontWeight:'bold'
                                }}
                            >
                                What is WellSpring?
                            </TypographyWellHeading>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#252525",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    mb: 3,
                                    fontSize: '18px',
                                    textAlign: "left",
                                    fontFamily:'Chromatica-Regular',
                                 }}
                            >
                                <>WellSpring uses an evidence-backed, “harm-reduction” approach  co&#8209;developed with people who use substances. </> <div style={{ marginBottom: '8px' }} />
                                WellSpring can help you reduce side effects of alcohol and other drugs and increase your overall wellness. At WellSpring, we do not require you to change your use in any way you don’t want to. You make changes on your own terms and timeline.
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize:'20px',
                                    color: "#252525",
                                    lineHeight: "22px",
                                    mb: 2,
                                    fontWeight: 'bold',
                                    textAlign: "left",
                                    fontFamily:'Chromatica-Bold'
                                }}
                            >
                                How Can WellSpring Help You?
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontFamily:'Chromatica-Regular',
                                    color: "#252525",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    mb: 1,
                                    fontSize: '18px',
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <img src={image1.default} alt="Metrics" style={{ width: "56px", height: "56px" }} />
                                Track metrics that show your positive changes over time.
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#252525",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    mb: 2,
                                    fontSize: '18px',
                                    fontFamily:'Chromatica-Regular',
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                }}
                            >
                                <img src={image2.default} alt="Metrics" style={{ width: "56px", height: "56px" }} />
                                Set intentions for your substance use and wellness.
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#252525",
                                    fontFamily:'Chromatica-Regular',
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    mb: 2,
                                    fontSize: '18px',
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                }}
                            >
                                <img src={image3.default} alt="Metrics" style={{ width: "56px", height: "56px" }} />
                                Analyze the science of substance use to make informed decisions about how to use them more safely.
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#252525",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    mb: 2,
                                    fontSize: '18px',
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    fontFamily:'Chromatica-Regular',
                                }}
                            >
                                With WellSpring, you don’t have to stop using to start enhancing wellness.
                            </Typography>
                            <Button
                                fullWidth
                                onClick={()=>{this.emailAccountLogin()}}
                                variant="contained"
                                sx={{
                                    padding: "10px",
                                    borderRadius: "12px",
                                    background:
                                        "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                                    textTransform: "none",
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    lineHeight: "20px",
                                    marginTop:'30px',
                                    marginBottom: '50px',            
                                    cursor:'pointer',
                                    fontFamily:'Chromatica-Black',
                                }}
                                data-test-id="wellspring"
                            >
                                Let's Get Started
                            </Button>
                            <Box
                            sx={{
                               
                                position: "absolute", 
                                bottom: 0,          
                                left: 32,          
                                width: "100%",       
                                padding: "10px",     
                                backgroundColor: "transparent",
                                textAlign: "left",   
                            }}
                        >
                            <Typography
                                variant="body2"
                                align="left"
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily:'Chromatica-Regular',
                                    lineHeight: "20px",
                                    color: "#252525",
                                }}
                            >
                                Looking for help:{" "}
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                        fontSize:'14px',
                                        fontFamily:'Chromatica-Bold',
                                    }}
                                    onClick={() => this.handleContactUsOpen()}
                                    data-test-id="contact-us"
                                >
                                    Contact us
                                </span>
                            </Typography>
                        </Box>
                        </Box>                    
                    </Box>

                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5.94}
                    lg={5.97}
                    sx={{
                        position: "relative",
                        padding: "50px",
                        background: `url(${image.default})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        "@media (max-width: 1025px)": {
                            display: "none"
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            color: "#EBFF00",
                            fontSize: "48px",
                            fontWeight: "700",
                            lineHeight: "56px",
                        }}
                    >
                        <img
                            alt="notation"
                            src={notation.default}
                            style={{ width: "30px",marginLeft:'10px',marginTop:'25px',marginBottom:'4px' }} 
                        />
                        <span style={webStyle.textHeading} >It's about</span>
                        <span style={webStyle.textHeading} >progress, not</span>
                        <span style={webStyle.textHeading} >perfection.</span>
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "20px",
                            fontSize: "40px",
                            left: "20px",
                            color: "#ffffff",
                            fontFamily: 'Josefin Sans',
                            fontWeight:'normal'
                        }}
                    >
                        <span  style={{ fontSize: "40px",marginLeft:'10px',fontFamily: 'Josefin Sans',
                                    fontWeight:'bold', color: "#EBFF00" }}>Well</span>Spring
                    </Box>
                </Grid>
            </Grid> 
        );
    }
}
const webStyle = {
   textHeading:{
    fontFamily:'Chromatica-Bold',
    marginBottom:'4px',
     marginLeft:'10px',
   }
}
const TypographyWellHeading = styled(Typography)({
    "@media (max-width: 495px)": {
      fontSize:'1.8rem'
    }
  })
// Customizable Area End