import React from 'react';
import {
    Grid,
    Button,
    Box
  } from "@mui/material";

import LandingPageController, {
    Props,
} from './LandingPageController';
import {landingscreen } from './assets'
import { styled } from "@mui/material/styles";

export const configJSON = require("./config.js");


export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Grid
            item
            style={{
            overflowX:'hidden',
              height: '100%',
              background: `url(${landingscreen.default})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center", 
              position: "relative",
              padding: "50px",
            }}
          >
            <StylesBoxHead
              sx={{
                display: 'flex',
                fontFamily: "Josefin Sans",
                fontSize: "125px", 
                lineHeight: "125px", 
                textAlign: "center", 
                marginBottom: "20px",
              }}
            >
              <span
                style={{
                  color: "#EBFF00",
                  marginRight: "10px", 
                  fontWeight: 700, 
                }}
              >
                Well
              </span>
              <span
                style={{
                  color: "#ffffff",
                }}>
                Spring
              </span>
            </StylesBoxHead>         
            <ButtonStyles data-test-id="intro" onClick={() => this.handleIntro()} style={{...webStyle.container,fontFamily:'Chromatica-Bold', marginTop:'60px', textTransform:'capitalize',}}>
                    Enter
            </ButtonStyles>
          </Grid>        
        )
    }
}

const ButtonStyles=styled(Button)({
    "@media  (max-width: 449px)": {
         width: '110px !important',
         height: '50px !important', 
        fontWeight: 0, 
         marginTop:'10px !important',
        fontSize:'18px',
      },
      "@media (min-width:450px) and (max-width: 599px)": {
        width: '150px !important',
        marginTop:'25px!important',
        height: '50px !important', 
        fontWeight: 0, 
        fontSize:'20px',
      },
    "@media (min-width:600px) and (max-width: 780px)": {
        width: '130px !important',
        height: '60px !important', 
        fontWeight: 200, 
        borderRadius: '18px', 
        marginTop:'30px!important',
        fontSize:'22px',
      },
    "@media (min-width:781px) and (max-width: 1200px)": {
        width: '140px !important',
        height: '70px !important', 
        fontWeight: 200, 
        borderRadius: '20px', 
        fontSize:'24px',
    },
    "@media (min-width:1200px) and (max-width: 2000px)": {
      width: '200px !important',
      marginTop:'70px !important',
      height: '70px !important', 
      fontWeight: 800,     
      fontSize:'34px !important',
  },
})
const StylesBoxHead = styled(Box)({
    "@media  (max-width: 449px)": {
        fontSize: "60px !important"
      },
    "@media (min-width:450px) and (max-width: 599px)": {
        fontSize: "70px !important"
      },
    "@media (min-width:600px) and (max-width: 780px)": {
        fontSize: "95px !important",
        fontWeight:0
      },
    "@media (min-width:781) and (max-width: 1200px)": {
      fontSize: "100px !important"
    },
    "@media (min-width:1200px) and (max-width: 2000px)": {    
      fontSize:'154px !important',
  },

   
  })
const webStyle = {
    container: {
        cursor:'pointer',
        fontWeight: 700, 
        borderRadius: '16px', 
        color: 'white', 
        width: '150px',
        height: '56px', 
        fontSize:'23px',   
        background: 'linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)'      
    },
}

