import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import dayjs from 'dayjs';
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  calendarOpen:string,
  checkedItems:any,
  valueOtherDay:boolean,
  checkAllValues:boolean,
  fixIssue:boolean,
  checkedIte:any,
  gridItems: any, 
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet:any,
  openCalender: string,
  openTime:boolean,
  formattedDateInter:string,
  setTimeValue:string,
  setCheckbox:boolean,
  setValueInput:string,
  valueSaved:string,
  areaToWork:any,
  selectedDate:string | Date,
  options: any,
  selectedValue: number,
  userName:any,
  imagesLoaded: boolean,
  loadedImages: any,
  OtherValue:boolean,
  dateErrorMessage:string,
  goalPopup: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}
interface NumberIndexedObject {
  [key: string]: string;
}

export default class OtherGoalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getValues:string="";
  getExistingValues: string = "";
  postValues:string="";
  putValues: string = "";
  storeValues:string="";
  stillWorkingApiCallId: string = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
  ];

    this.state = {
      isLoading: false,
      gridItems: [], 
      checkedItems:{},
      valueOtherDay:false,
      calendarOpen:'',
      fixIssue:false,
      checkAllValues:false,
      checkedIte:{},
      options: [], 
      newDateData: "",
      timeSet:'',
      currDate: {},
      openCalender: "",
      openTime:false,
      formattedDateInter:'ok',
      setTimeValue:'',
      setCheckbox:false,
      setValueInput:'',
      valueSaved:"",
      areaToWork:[],
      selectedDate:"",
      selectedValue: 0,
      userName:'',
      imagesLoaded: false,
      loadedImages: {},
      goalPopup: false,
      OtherValue:false,
      dateErrorMessage:'',
    };
    this.handleKeyOthers=this.handleKeyOthers.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  throttle(fn:any, wait:any) {
    let isCalled = false;
    return function(...args: any) {
      if (!isCalled) {
        fn(...args);
        isCalled = true;
        setTimeout(() => {
          isCalled = false;
        }, wait);
      }
    };
  }
  async componentDidMount() {
    this.setState({
      userName: localStorage.getItem("username")
    })
    if (typeof window !== "undefined" && typeof window.addEventListener === "function") {
      window.addEventListener("keydown", this.handleKeyOthers);
    }
    setTimeout(() => {
    this.scrollRef.current &&
      this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    localStorage.setItem("pageNavigate","")
    this.getAllDataOtherGoals();
    this.getOtherSubstanceData();
    this.generateOptions();
  }
  async componentWillUnmount() {
   
      window.removeEventListener("keydown", this.handleKeyOthers);
    
   
  }
  preloadImages = async () => {
    const { areaToWork } = this.state;
    const images: { [key: string]: string } = {};

    const imagePromises = areaToWork.map((item: { id: any; icon: any }) => {
      const img = new Image();
      img.src = item.icon.url;
  
      return new Promise<void>((resolve) => {
        img.onload = () => {
          this.setState(prevState => ({
            loadedImages: { ...prevState.loadedImages, [item.id]: img.src }
          }));
          resolve();
        };
      });
    });
    await Promise.all(imagePromises);

    this.setState({ loadedImages: images, imagesLoaded: true });
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
 
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiRequestCallId != null) {
        if (apiRequestCallId === this.getValues) {
              this.setState({areaToWork:responseJson?.goal_areas},this.fetchGridItems)
      
            }
            if (apiRequestCallId === this.postValues) {
             this.postValuesRespone(responseJson)
      
            } else if (apiRequestCallId === this.getExistingValues) {
              this.handleExistingApiResponse(responseJson);
            } else if (apiRequestCallId === this.putValues) {
              this.postValuesRespone(responseJson)
             }
        
      }
    }
  }

  handleExistingApiResponse = (responseJson: any) => {
    if(responseJson?.goal) {
      const doesMatchFirstFive = this.state.options
        .slice(0, 5)
        .some((opt: any) => opt.date === responseJson.goal.target_date);
      this.setState({
        checkedItems: {[responseJson.goal.goal_area_id]: true},
        selectedValue: responseJson.goal.goal_area_id,
        setValueInput: responseJson.goal.responses?.answer1,
        valueOtherDay: responseJson.goal.is_other_day || !doesMatchFirstFive,
        timeSet: responseJson.goal.reminder_time,
        fixIssue: true,
        formattedDateInter: responseJson.goal.target_date,
        setCheckbox: responseJson.goal.daily_goal,
        calendarOpen: doesMatchFirstFive ? "" : "other",
      }, this.fetchGridItems);
    }
  };

  formatDate(date:any) {
    const dateMoment = moment(date); 
    const formattedDate = dateMoment.format('ddd, MMMM D')
    const isToday = dateMoment.isSame(moment(), 'day'); 
    return isToday ? formattedDate.replace(',', '') : formattedDate;
}

generateOptions() {
  const today = new Date();
  const options = [
      { 
          value: 'today', 
          label: `Today, ${this.formatDate(today)}`, 
          date: this.formatDateYYYYMMDD(today) 
      },
  ];
  for (let i = 1; i <= 4; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      options.push({
          value: `nextDay${i}`,
          label: `${this.formatDate(nextDate)}`,
          date: this.formatDateYYYYMMDD(nextDate) 
      });
  }
  options.push({ value: 'other', label: 'Other Day', date: '' }); 
  this.setState({ options });
}

formatDateYYYYMMDD(date:any) {
  if (!date){
    return;
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  return `${month}-${day}-${year}`;
}
handleKeyOthers(event: { key: string }) {
  let directionWay = 0;
  if (typeof document.getElementById === 'function') {
    const modalContent = document.getElementById("modal-others");
      if (event.key === "ArrowDown") {
        directionWay = 100;
      } else if (event.key === "ArrowUp") {
        directionWay = -100;
      }
      if (directionWay !== 0 && modalContent) {
        modalContent.scrollBy({ top: directionWay, behavior: "smooth" });
    }
  }
}

handleChange = (event:any) => {
  let answer=this.formatDateChange(event.date)
  this.setState({ formattedDateInter:answer,valueOtherDay:false,fixIssue:true,calendarOpen:event.value})
};
hamburgerPage(){
  localStorage.setItem("pageNavigate","OtherGoals")
  this.props.navigation.navigate("HamburgerMenu")
}
async goalsPage(){
  const selectedCardId = await getStorageData("selectedCardId");
  if(selectedCardId) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "GoalsInformation");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  } else {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Goals");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
}

  fetchGridItems = async () => {
    await this.preloadImages();
    const { areaToWork, loadedImages } = this.state;
    if (!Array.isArray(areaToWork)) {
      return;
    }
    const gridItems = areaToWork.map((item: { id: any; name: any; icon:any;}) => ({
      id: item.id,
      label: item.name ,
      image: loadedImages[item.id] || item.icon.url,
    }));
    this.setState({
      gridItems,
      isLoading: false,
    }, () => {
      this.setState({
        valueSaved: this.state.gridItems.filter((otherGoal: any) => otherGoal.id === this.state.selectedValue)?.[0]?.label,
      });
    });
  }
  postOtherSubstanceData = () => {
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: data
    };
    const answer=Object.keys(this.state.checkedItems)[0];
    const httpBody = { 
        other_life_goal: {
          target_date:this.state.formattedDateInter,
          reminder_time: this.state.timeSet,
          daily_goal: this.state.setCheckbox ,
          goal_area_id:answer,
          is_other_day:  this.state.valueOtherDay,
          responses: {
            question1: "What is one thing you can do for this week?",
            answer1:this.state.setValueInput
        }
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postValues = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  putOtherSubstanceData = async () => {
    let data = localStorage.getItem("token") || "{}";
    const selectedCardId = await getStorageData("selectedCardId");
    const otherGoalId = localStorage.getItem("otherGoalId");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: data
    };
    const answer=Object.keys(this.state.checkedItems)[0];
    const httpBody = { 
        other_life_goal: {
          target_date:this.state.formattedDateInter,
          reminder_time: this.state.timeSet,
          daily_goal: this.state.setCheckbox ,
          goal_area_id:answer,
          is_other_day:  this.state.valueOtherDay,
          responses: {
            question1: "What is one thing you can do for this week?",
            answer1:this.state.setValueInput
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putValues = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postData}/${selectedCardId || otherGoalId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  getAllDataOtherGoals = async () => {
    let data = localStorage.getItem("token") || "{}";
    const selectedCardId = await getStorageData("selectedCardId");
    const otherGoalId = localStorage.getItem("otherGoalId");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: data
    };
    if(selectedCardId || otherGoalId) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getExistingValues = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_forms/other_life_goals/${selectedCardId || otherGoalId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);  
    } else {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.storeValues = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allValues
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
getOtherSubstanceData = () => {
  this.setState({ isLoading: true });
  let data = localStorage.getItem("token") || "{}";
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: data
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getValues = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.othersubstance
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

};
async checkAllValuesData(){
  this.setState({checkAllValues:true})
  const selectedCardId = await getStorageData("selectedCardId");
  const otherGoalId = localStorage.getItem("otherGoalId");
  if(this.state.timeSet &&  this.state.setValueInput!=''&& Object.keys(this.state.checkedItems).length !== 0 && (this.state.formattedDateInter!==''&& this.state.formattedDateInter!=='ok')){
    if(selectedCardId || otherGoalId) {
      this.putOtherSubstanceData();
    } else {
      this.postOtherSubstanceData();
    }
  }
}
handleChangeInput=(event:any)=>{
this.setState({setValueInput:event.target.value})
}
openPopUp(key: number) {
  this.setState({
    openCalender: key.toString(),
    dateErrorMessage: "",
  });
}
openTimer() {
  this.setState({
    openTime: true
  });
}

closePopUp() {
  this.setState({
    openCalender: ""
  });
}

handleCheckboxChange = (id: any, item: any) => {
   this.setState(prevState => {
    const isChecked = prevState?.checkedItems[id] === true;
    const newCheckedItems = isChecked
      ? {} 
      : { [id]: true }; 

    return {
      setValueInput:'',
      checkedItems: newCheckedItems, 
      valueSaved: isChecked ? null : item,
    };
  });
};
handleDataChangeTime = (newDate: any) => {
  const formattedDate = dayjs(newDate).format('hh:mm A');
  this.setState({ setTimeValue: formattedDate });
};
handleUserTime=()=>{
  this.setState({timeSet:this.state.setTimeValue})
  this.setState({openTime:false})
}
formatDateChange = (dateStr: any) => {
  if (!dateStr) {
    return ''; 
  }
  const [year, day, month] = dateStr?.split('-');
  if (!year || !day || !month) {
    return ''; 
  }
 
  return `${year}-${day}-${month}`; 
};
  handleDataCalendar=(newDate:any)=>{
    const formattedDate = dayjs(newDate.$d).format('MM-DD-YYYY');
    this.setState({selectedDate:formattedDate})
  }
  handleDate(e: any) {
    let date = e["$d"];
    let finalDate = date?.getDate() + '/' + (date?.getMonth() + 1) + '/' + date?.getFullYear();
    this.setState({
      newDateData: finalDate
    });
  }
  handleTime(e:any){
    this.setState({
      timeSet: e,
      openTime: false
  });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  handlePopupTime(keyAnswer: any) {
    if (keyAnswer) {return true;} else {
      return false;
    }
  }
  confirmCal() {

    const selectedDate = this.state.selectedDate; 
    const currentDate = dayjs(); 
  
    if(selectedDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (dayjs(selectedDate).isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return; 
    }
  
    this.setState({
      openCalender: "",
      OtherValue: true,
      dateErrorMessage: "",
      formattedDateInter: selectedDate.toString(),
      valueOtherDay:true
    });
  }
  handleCheckbox = () => {
    if(this.state.setCheckbox){
      this.setState({setCheckbox:false})
    }
    else{
      this.setState({setCheckbox:true})
    }
  }
 handleColor(){
 return this.state.setCheckbox ? '#00A7D1' : '#E3E3E3'
 }
   getItemBorder  (itemId: string){
    return this.state.checkedItems[itemId] ? '2px solid transparent' : '2px solid #E3E3E3';
  };
  getItemColor  (itemId: string){
    return this.state.checkedItems[itemId]
    ? 'linear-gradient(#EFF8FB, #EFF8FB) padding-box, linear-gradient(#EBFF00, #00A7D1) border-box'
    : 'white'
  };
  handleClose=()=>{
    this.setState({openTime:false})
  }

  postValuesRespone = async (responseJson:any) => {
    const selectedCardId = await getStorageData("selectedCardId");
    const otherGoalId = localStorage.getItem("otherGoalId");
    const newUseFound = localStorage.getItem("NewUseFound");
    const stillWorking = await getStorageData("stillWorking");
    if (responseJson?.goal?.id) {
      localStorage.setItem("otherGoalId", responseJson.goal.id);
      if(stillWorking === "true") {
        setStorageData("weekValue", "0");
      }
      if(selectedCardId || otherGoalId) {
        if(stillWorking === "true") {
          const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          };
          const otherMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.stillWorkingApiCallId = otherMessage.messageId;
          otherMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_forms/other_life_goals/goal_still_working?id=${selectedCardId}`
          );
          otherMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          otherMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );
          runEngine.sendMessage(otherMessage.id, otherMessage);
        }
        const redirectionRoute = newUseFound === "Yes" ? "SaferUserStrategies" : "GoalsInformation";
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), redirectionRoute);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      } else {
        this.setState({ goalPopup: true });
      }
    } 
  }

  handleAddMoreGoal = () => {
    this.setState({ goalPopup: false }, () => {
      this.setState({
        setValueInput: "",
        valueSaved: "",
        timeSet: "",
        selectedDate: "",
        selectedValue: 0,
        valueOtherDay: false,
        checkedItems: "",
        fixIssue: false,
        formattedDateInter: "ok",
        setCheckbox: false,
        checkAllValues: false,
      });
      const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
      redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Goals");
      redirectMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(redirectMsg);
    })
  };

  handleDoneClick = () => {
    this.setState({ goalPopup: false }, () => {
      const newUseFound =localStorage.getItem("NewUseFound")
      const finalUrl = newUseFound === "Yes" ? "SaferUserStrategies" : "DashboardInformation";
     if( newUseFound==='Yes'){
      localStorage.setItem("valueMe","OtherLifeGoals")
      }
      const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
      redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), finalUrl);
      redirectMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(redirectMsg);
    })
  };
  // Customizable Area End
}
