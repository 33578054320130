import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  // [key: string]: string;
  user:string;
  description:string;
  submitButtonClick: boolean;
  disabledData:boolean,
  contactStatus:boolean,
  handleError:boolean,
  token:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContactUsController extends BlockComponent<
  Props,
  S,
  SS
> {

  getCategoryHandleValue:string="";
  postSUSCategoryApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      password: "",
      user:"",
      description:"",
      token:'',
      submitButtonClick: false,
      handleError:false,
      contactStatus:false,
      disabledData:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
 
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.postSUSCategoryApiCallId && responseJson.errors
        ) {

            this.setState({handleError:responseJson?.errors[0]?.contact,contactStatus:false,disabledData:false})
            
        }
        else if(apiRequestCallId === this.postSUSCategoryApiCallId && responseJson.data){
            this.setState({handleError:false,contactStatus:true,disabledData:false})
        }
      }
    }
  }
  handleCloseToast=()=>{
    this.setState({contactStatus:false,
      user: "", 
      description: "",
      submitButtonClick: false,
    })
  }

handleContactUsClose = () => {
  let data = localStorage.getItem("ContactUs") || "{}";
  this.props.navigation.navigate(data);
  localStorage.removeItem("ContactUs");
}
/* istanbul ignore next */
handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = e.target;

  this.setState((prevState) => ({
    ...prevState,
    [name]: value,
    handleError: name === "user" && value.trimStart().length === 0
  } as Pick<S, keyof S>));
};

/* istanbul ignore next */
handleSubmit = () =>{
  this.setState({disabledData:true})
  this.setState({ submitButtonClick: true });
  const isValidDescription = /^[^a-zA-Z]*$/.test(this.state.description);
  const isValidUser = /^[^a-zA-Z]*$/.test(this.state.user);

  if (!this.state.user || !this.state.description) {
    return; 
  }
  if(this.state.description?.length > 1  && this.state.user?.length > 1 && !isValidDescription && !isValidUser){
 
  const formData = new FormData()
  formData.append("contact[name]",this.state.user)
  formData.append("contact[description]", this.state.description.trimStart());
  const header = {
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postSUSCategoryApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.contactUsEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage), 
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage), 
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.loginAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

}
}

  // Customizable Area End