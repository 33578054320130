import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import dayjs from "dayjs";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  children?: React.ReactNode;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  valueHold:any,
  categoryNameStore:any,
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  tobacoMeasureType:string
  tobacoReduceState:string
  cigarettesReduceType:string
  lastWeekCigarettesCount:number
  nextweekCigarettesReduceCount:number
  pastSevenDaysCigarettesCount:number
  nextSevenDaysCigarettesCount:number
  isTakingBreakCigarettes:string,
  tobaccoBreakDay: dayjs.Dayjs[]; 
  tobaccoBreakSelectedDate:string;
  selectedTab:any,
  tobaccoCalendar:boolean,
  tobaccoCalendarSelectedDate:any,
  tobaccoOtherMeasureTypeTextValue:string,
  tobaccoreduceHowMuchTextValue:string
  tobaccoOthersTextValue:string,
  upperReduceState:string,
  upperMetricValue:string,
  lastWeekUppersOuncesCount:number,
  nextweekUppersOuncessReduceCount:number,
  pastSevenDaysUppersCount:number,
  nextSevenDaysUppersCount:number,
  isTakingBreakUppers:string,
  uppersBreakSelectedDate:string,
  uppersCalendarSelectedDate:any,
  lastWeekUppersHitsCount:number,
  nextweekUppersHitsReduceCount:number,
  lastWeekUppersDollarsTextValue:string,
  nextweekUppersDollarsReduceTextValue:string,
  uppersOthersTextValue:string,
  downersReduceState:any,
  downersMetricValue:string,
  lastWeekDownersOuncesCount:number,
  nextweekDownersOuncessReduceCount:number,
  pastSevenDaysDownersCount:number,
  nextSevenDaysDownersCount:number,
  isTakingBreakDowners:string,
  downersBreakSelectedDate:string
  lastWeekDownersHitsCount:number,
  nextweekDownersHitsReduceCount:number,
  lastWeekDownersDollarsTextValue:string,
  nextweekDownersDollarsReduceTextValue:string,
  DownersOthersTextValue:string,
  downersCalendarSelectedDate:any,
  UppersButtonCalled:boolean,
  allowUppersPostApiCall:boolean
  TobaccoButtonCalled:boolean,
  allowTobaccoPostApiCall:boolean,
  DownersButtonCalled:boolean,
  allowDownersPostApiCall:boolean,
  openCalender:any
  selecteTobaccodDate:any,
  selectedUppersDate:any,
  selectedDownersDate:any,
  userName:any,
  selectedValue: string,
  selectedValueOption: string,
  drinkCount: number,
  drinkCountSecond: number,
  drinkCountOften: number,
  drinkCountOftenSecond: number,
  selectedDate: string,
  selectedValueSubOption: string,
  OthersResponse: string,
  formattedDateInter: string,
  textdollars: number,
  textdollarssecond: number,
  CannabisSecond: number,
  cannabisCount: number,
  isLoading: boolean,
  selectedCardId: any,
  dateErrorMessage:string,
  OtherValue:boolean,
  newData: any,
  hideDateError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  getCategoryValueExtrat:string="";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getUppersApiCallId: string = "";
  postUppersApiCallId: string = "";
  postTobaccoApiCallId: string = "";
  getTobaccoApiCallId: string = "";
  postDownersApiCallId: string = "";
  getDownersApiCallId: string = "";
  fetchSubstanceGoalApiCallId: string = "";
  putSubstanceTobaccoApiCallId: string = "";
  putSubstanceUppersApiCallId: string = "";
  putSubstanceDownersApiCallId: string = "";
  scrollRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      categoryNameStore:[],
      valueHold:[],
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      tobacoMeasureType: "Cigarettes",
      tobacoReduceState: "reduceHowMuch",
      cigarettesReduceType: "numberOfCigarettes",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      isTakingBreakCigarettes: "",
      tobaccoBreakDay: [],
      tobaccoBreakSelectedDate: "",
      selectedTab: "",
      tobaccoOtherMeasureTypeTextValue: "",
      tobaccoCalendar: false,
      tobaccoCalendarSelectedDate: '',
      tobaccoreduceHowMuchTextValue: "",
      tobaccoOthersTextValue: "",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      upperReduceState: "UppersReduceHowMuch",
      upperMetricValue: "ounces",
      pastSevenDaysUppersCount: 0,
      isTakingBreakUppers: "",
      nextSevenDaysUppersCount: 0,
      uppersBreakSelectedDate: "",
      uppersCalendarSelectedDate: '',
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      uppersOthersTextValue: "",
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      downersReduceState: "downersReduceHowMuch",
      lastWeekDownersOuncesCount: 0,
      downersMetricValue: "ounces",
      pastSevenDaysDownersCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "",
      downersBreakSelectedDate: "",
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      downersCalendarSelectedDate: '',
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      DownersOthersTextValue:'',
      UppersButtonCalled:false,
      allowUppersPostApiCall:false,
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false,
      DownersButtonCalled:false,
      allowDownersPostApiCall:false,
      openCalender:'',
      selecteTobaccodDate:'',
      selectedUppersDate:'',
      selectedDownersDate:'',
      userName:'',
      selectedValue: "Reduce how much",
      selectedValueOption: "",
      drinkCount: 0,
      drinkCountSecond: 0,
      drinkCountOften: 0,
      drinkCountOftenSecond: 0,
      selectedDate: "",
      selectedValueSubOption: "Ounces",
      cannabisCount: 0,
      CannabisSecond: 0,
      formattedDateInter: "",
      OthersResponse: "",
      textdollars: 0,
      textdollarssecond: 0,
      isLoading: false,
      selectedCardId: null,
      dateErrorMessage:'',
      OtherValue:false,
      newData: true,
      hideDateError: true,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {

        switch (apiRequestCallId) {
          case this.postUppersApiCallId:
            this.postUppersResponse(responseJson)

            localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
            break;
          case this.putSubstanceUppersApiCallId:
            this.postUppersResponse(responseJson)
            break;
          case this.getUppersApiCallId:
            this.getUppersApiResponse(responseJson)
            break;
          case this.postTobaccoApiCallId:

          localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
            this.postTobaccoApiResponse(responseJson)
            break;
          case this.putSubstanceTobaccoApiCallId:
            this.postTobaccoApiResponse(responseJson)
            break;
          case this.getTobaccoApiCallId:
            this.getTobaccoApiResponse(responseJson)
            break;
          case this.getCategoryValueExtrat:
            this.handleCategory(responseJson)
          case this.postDownersApiCallId:

          localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
            this.postDownersResponse(responseJson)  
            break;
          case this.putSubstanceDownersApiCallId:
            this.postDownersResponse(responseJson)  
            break;
          case this.getDownersApiCallId:
            this.getDownersApiResponse(responseJson)
            break;      
          case this.fetchSubstanceGoalApiCallId: 
            this.handleSubstanceGoalAPIResponse(responseJson);
            break;
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkCategoryAvailability = (selectedValue: string) => {
    const { selectedCardId, newData } = this.state;
    const checkData = !newData && selectedCardId;
      switch(selectedValue) {
        case "Alcohol":
          return checkData
            ? this.state.selectedTab === "alcohol"
            : this.state.categoryNameStore?.includes("Alcohol")
        case "Cannabis":
          return checkData
            ? this.state.selectedTab === "cannabis"
            : this.state.categoryNameStore?.includes("Cannabis")
        case "Tobacco or Nicotine":
          return checkData
            ? this.state.selectedTab === "tobaccoOrNicotine"
            : this.state.categoryNameStore?.includes("Tobacco or Nicotine")
        case "Uppers":
          return checkData
            ? this.state.selectedTab === "uppers"
            : this.state.categoryNameStore?.includes("Uppers")
        case "Downers":
          return checkData
            ? this.state.selectedTab === "downers"
            : this.state.categoryNameStore?.includes("Downers");
        default:
          return this.state.categoryNameStore?.includes("Others");
    }
  };

  fetchExistingSubstanceGoalData = async () => {
    this.setState({ isLoading: true });
    const selectedCardId = await getStorageData("selectedCardId");
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubstanceGoalApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSelectedTab = (selectedTab: string) => {
    switch(selectedTab) {
      case "Alcohol":
        return "alcohol";
      case "Cannabis":
        return "cannabis";
      case "tobaccoOrNicotine":
        return "tobaccoOrNicotine";
      case "uppers":
        return "uppers";
      case "downers":
        return "downers";
      default:
        return "Others";
    }
  };

  getSelectedValue = (selectedValue: string) => {
    switch(selectedValue) {
      case "reduce_how_much":
        return "Reduce how much";
      case "reduce_how_often":
        return "Reduce how often";
      case "safer_healthier":
        return "Safer/healthier";
      case "stop_completely":
        return "Stop completely";
      case "others":
        return "Other";
      default:
        return "Reduce how much";
    }
  };

  getSelectedSubValue = (selectedValue: string) => {
    switch(selectedValue) {
      case "Ounces":
        return "Ounces";
      case "Dollars":
        return "$$";
      case "Hits":
        return "Hits";
      default:
        return "Ounces";
    }
  };

  handleSubstanceGoalAPIResponse = (responseJson: any) => {
    if(responseJson?.id) {
      const selectedHeading = this.getSelectedTab(responseJson?.category_name);
      localStorage.setItem("selectedHeading", selectedHeading);
      localStorage.setItem("selectedSubValue", this.getSelectedValue(Object.keys(responseJson?.responses)[0]))
      this.setState({
        isLoading: false,
        selectedTab: this.getSelectedTab(responseJson?.category_name),
        selectedValue: this.getSelectedValue(Object.keys(responseJson?.responses)[0] as string),
        selectedValueOption: responseJson?.responses?.stop_completely?.answer1 || '',
        drinkCount: responseJson?.responses?.reduce_how_much?.answer1 || responseJson?.responses?.reduce_how_much?.Ounces?.answer1 || 0,
        drinkCountSecond: responseJson?.responses?.reduce_how_much?.answer2 || responseJson?.responses?.reduce_how_much?.Ounces?.answer2 || 0,
        drinkCountOften: responseJson?.responses?.reduce_how_often?.answer1 || 0,
        drinkCountOftenSecond: responseJson?.responses?.reduce_how_often?.answer2 || 0,
        selectedDate: moment(responseJson?.responses?.stop_completely?.answer2).format("MM/DD/YYYY") || "",
        selectedValueSubOption: responseJson?.responses?.reduce_how_much ? this.getSelectedSubValue(Object.keys(responseJson?.responses?.reduce_how_much)?.[0] as string) : "",
        OthersResponse: responseJson?.responses?.others?.answer1 || responseJson?.responses?.others?.answer || '',
        formattedDateInter: moment(responseJson?.responses?.stop_completely?.answer2).format("MM/DD/YYYY"),
        textdollars: responseJson?.responses?.reduce_how_much?.Dollars?.answer1 || 0,
        textdollarssecond: responseJson?.responses?.reduce_how_much?.Dollars?.answer2 || 0,
        CannabisSecond: responseJson?.responses?.reduce_how_much?.Hits?.answer2 || 0,
        cannabisCount: responseJson?.responses?.reduce_how_much?.Hits?.answer1 || 0
      });
      this.checkActiveTab(selectedHeading, responseJson);
    }
  };

  checkActiveTab = (selectedHeading: any, responseJson: any) => {
    switch (selectedHeading) {
      case "tobaccoOrNicotine":
        this.getTobaccoApiResponse(responseJson, true);
        break;
      case "uppers":
        this.getUppersApiResponse(responseJson, true);
        break;
      case "downers":
        this.getDownersApiResponse(responseJson, true);
        break;
      default:
        break;
    }
  };

  handleCategory = (responseJson: any) => {
    const categoryNames = responseJson?.categories?.map((category: any) => category?.category_name);
    this.setState({categoryNameStore:categoryNames})
    console.log(categoryNames,"categoryNames")
    const predefinedSequence = ['Alcohol', 'Cannabis', 'Tobacco or Nicotine', 'Uppers', 'Downers', 'Others'];
const firstMatchingCategory = predefinedSequence.find(category => categoryNames?.includes(category));
const sortedCategoryNames = categoryNames?.sort((a: any, b: any) => {
  return predefinedSequence?.indexOf(a) - predefinedSequence?.indexOf(b);
});

const mappedValues = sortedCategoryNames?.map((item: any) => {
  switch (item) {
      case "Cannabis":
          return "cannabis";
      case "Alcohol":
          return "alcohol";
      case "Uppers":
          return "uppers";
      case "Downers":
          return "downers";
      case "Tobacco or Nicotine":
          return "tobaccoOrNicotine";
      default:
          return item; 
  }
});
this.setState({valueHold:mappedValues})
localStorage.setItem("valuesSequence",mappedValues)
setStorageData("selectedSubstance", sortedCategoryNames);

const answer=localStorage.getItem("selectedTab")

if( 
 answer!=null && answer!=''){
  this.setState({selectedTab:answer})
  }

  else {
if(firstMatchingCategory==='Tobacco or Nicotine'){
  this.setState({ selectedTab:'tobaccoOrNicotine'})
}
else if(firstMatchingCategory==='Alcohol'){
  this.setState({ selectedTab:'alcohol'})
}
else if(firstMatchingCategory==='Cannabis'){
  this.setState({ selectedTab:'cannabis'})
}
else if(firstMatchingCategory==='Uppers'){
  this.setState({ selectedTab:'uppers'})
}
else if(firstMatchingCategory==='Downers'){
  this.setState({ selectedTab:'downers'})
}
  }
  };
  
  postUppersResponse=(responseJson:any)=>{
    if (
      responseJson.message ===
      "Substance goal response saved successfully" || responseJson.message ===
      "Substance goal response updated successfully"
    ) {
      const selectedCardId = responseJson?.substance_goal_response?.id;
      setStorageData("selectedCardId", selectedCardId);
      if(responseJson.message === "Substance goal response saved successfully") {
        setStorageData("newData", true);
      }
      localStorage.setItem("currentValue","uppers")
      this.props.navigation.navigate("SubstanceGoals")
    }
  }
  getUppersApiResponse=(responseJson:any, isExisting?: boolean)=>{
    const UppersResponses = responseJson?.responses;
    let selectedState =
      UppersResponses?.SelectedReduceState?.selectedState;
    let SelectedUpperMetricValue =
      UppersResponses?.selected_value?.selectedValue?.upperMetricValue;
    let SelectedStateValue =
      UppersResponses?.selected_value?.selectedValue;
    const checkDateUppers = isExisting
      ? this.checkDateExists(SelectedStateValue?.uppersBreakSelectedDate)
      : !this.checkDateExists(SelectedStateValue?.uppersBreakSelectedDate);
    if(isExisting) {
      selectedState =
        UppersResponses?.[Object.keys(responseJson?.responses)[0]]?.SelectedReduceState?.selectedState;
      SelectedUpperMetricValue =
        UppersResponses?.[Object.keys(responseJson?.responses)[0]]?.selected_value?.selectedValue?.upperMetricValue;
      SelectedStateValue =
        UppersResponses?.[Object.keys(responseJson?.responses)[0]]?.selected_value?.selectedValue;
    }
      if(selectedState){
        this.setState({
          upperReduceState: selectedState,
        });
      }
    if (selectedState == "UppersReduceHowMuch") {
      this.setState({
        upperMetricValue: SelectedUpperMetricValue,
      });
      if (SelectedUpperMetricValue == "ounces") {
        this.setState({
          lastWeekUppersOuncesCount:
            SelectedStateValue?.lastWeekUppersOuncesCount,
          nextweekUppersOuncessReduceCount:
            SelectedStateValue?.nextweekUppersOuncessReduceCount,
        });
      } else if (SelectedUpperMetricValue == "dollars") {
        this.setState({
          lastWeekUppersDollarsTextValue:
            SelectedStateValue?.lastWeekUppersDollarsTextValue,
          nextweekUppersDollarsReduceTextValue:
            SelectedStateValue?.nextweekUppersDollarsReduceTextValue,
        });
      } else if (SelectedUpperMetricValue == "hits") {
        this.setState({
          lastWeekUppersHitsCount:
            SelectedStateValue?.lastWeekUppersHitsCount,
          nextweekUppersHitsReduceCount:
            SelectedStateValue?.nextweekUppersHitsReduceCount,
        });
      }
    } else if (selectedState == "UppersReduceHowOften") {
      this.setState({
        pastSevenDaysUppersCount:
          SelectedStateValue?.pastSevenDaysUppersCount,
        nextSevenDaysUppersCount:
          SelectedStateValue?.nextSevenDaysUppersCount,
      });
    } else if (selectedState == "UppersStopCompletely") {
      this.setState({
        isTakingBreakUppers: SelectedStateValue?.isTakingBreakUppers,
        uppersBreakSelectedDate:
          this.checkDateExists(SelectedStateValue?.uppersBreakSelectedDate) ? moment(SelectedStateValue?.uppersBreakSelectedDate).format("MM/DD/YYYY") : "OtherDay",
        uppersCalendarSelectedDate:
          checkDateUppers ? moment(SelectedStateValue?.uppersBreakSelectedDate).format("MM/DD/YYYY") : SelectedStateValue?.uppersCalendarSelectedDate,
      });
    } else if (selectedState == "Others") {
      this.setState({
        uppersOthersTextValue: SelectedStateValue?.uppersOthersTextValue,
      });
    }
  }

  postTobaccoApiResponse=(responseJson:any)=>{
    

    if (
      responseJson.message ===
      "Substance goal response saved successfully" || 
      responseJson.message ===
      "Substance goal response updated successfully"
    ) {
      const selectedCardId = responseJson?.substance_goal_response?.id;
      setStorageData("selectedCardId", selectedCardId);
      if(responseJson.message === "Substance goal response saved successfully") {
        setStorageData("newData", true);
      }
      localStorage.setItem("currentValue","tobaccoOrNicotine")
      this.props.navigation.navigate("SubstanceGoals")
    }
}
  getTobaccoApiResponse=(responseJson:any, isExisting?: boolean)=>{
    const TobaccoResponses = responseJson?.responses;
    let selectedState =
      TobaccoResponses?.SelectedReduceState?.selectedState;
    let SelectedStateValue =
      TobaccoResponses?.selected_value?.selectedValue;
    if(isExisting) {
      selectedState =
        TobaccoResponses?.[Object.keys(responseJson?.responses)[0]]?.SelectedReduceState?.selectedState;
      SelectedStateValue =
        TobaccoResponses?.[Object.keys(responseJson?.responses)[0]]?.selected_value?.selectedValue;
    }
    const tobacoMeasureType = SelectedStateValue?.tobacoMeasureType;
    const cigarettesReduceType = SelectedStateValue?.cigarettesReduceType;
    const checkDateTobacco = isExisting
      ? this.checkDateExists(SelectedStateValue?.tobaccoBreakSelectedDate)
      : !this.checkDateExists(SelectedStateValue?.tobaccoBreakSelectedDate);
      if(selectedState){
      this.setState({
        tobacoReduceState: selectedState,
      });
    }
      if (selectedState == "reduceHowMuch") {
        this.setState({
          cigarettesReduceType: cigarettesReduceType,
          tobacoMeasureType: tobacoMeasureType
        });
        if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "numberOfCigarettes") {
          this.setState({
            lastWeekCigarettesCount: SelectedStateValue?.lastWeekCigarettesCount,
            nextweekCigarettesReduceCount: SelectedStateValue?.nextweekCigarettesReduceCount
          });
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "numberOfCigarettes") {
          this.setState({
            tobaccoOtherMeasureTypeTextValue: SelectedStateValue?.tobaccoOtherMeasureTypeTextValue,
            lastWeekCigarettesCount: SelectedStateValue?.lastWeekCigarettesCount,
            nextweekCigarettesReduceCount: SelectedStateValue?.nextweekCigarettesReduceCount
          });
        } else if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "Others") {
          this.setState({
            tobaccoreduceHowMuchTextValue: SelectedStateValue?.tobaccoreduceHowMuchTextValue,
          });
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "Others") {
          this.setState({
            tobaccoOtherMeasureTypeTextValue: SelectedStateValue?.tobaccoOtherMeasureTypeTextValue,
            tobaccoreduceHowMuchTextValue: SelectedStateValue?.tobaccoreduceHowMuchTextValue,
          });
        }
      } else if (selectedState == "reduceHowOften") {
        this.setState({
          pastSevenDaysCigarettesCount:
            SelectedStateValue?.pastSevenDaysCigarettesCount,
          nextSevenDaysCigarettesCount:
            SelectedStateValue?.nextSevenDaysCigarettesCount,
        });
      } else if (selectedState == "stopCompletely") {
        this.setState({
          isTakingBreakCigarettes: SelectedStateValue?.isTakingBreakCigarettes,
          tobaccoBreakSelectedDate:
            this.checkDateExists(SelectedStateValue?.tobaccoBreakSelectedDate) ? moment(SelectedStateValue?.tobaccoBreakSelectedDate).format("MM/DD/YYYY") : "OtherDay",
          tobaccoCalendarSelectedDate:
            checkDateTobacco ? moment(SelectedStateValue?.tobaccoBreakSelectedDate).format("MM/DD/YYYY") : SelectedStateValue?.tobaccoCalendarSelectedDate,
        });
      } else if (selectedState == "Others") {
        this.setState({
          tobaccoOthersTextValue: SelectedStateValue?.tobaccoOthersTextValue,
        });
      }
  }

  postDownersResponse=(responseJson:any)=>{
    
    if (
      responseJson.message ==
      "Substance goal response saved successfully" || responseJson.message ==
      "Substance goal response updated successfully"
    ) {
      const selectedCardId = responseJson?.substance_goal_response?.id;
      setStorageData("selectedCardId", selectedCardId);
      if(responseJson.message === "Substance goal response saved successfully") {
        setStorageData("newData", true);
      }
      localStorage.setItem("currentValue","downers")
      this.props.navigation.navigate("SubstanceGoals")
    }
  }

  getDownersApiResponse=(responseJson:any, isExisting?: boolean)=>{
    const DownersResponses = responseJson?.responses;
    let selectedState =
      DownersResponses?.SelectedReduceState?.selectedState;
    let SelectedStateValue =
      DownersResponses?.selected_value?.selectedValue;
    const checkDateDowners = isExisting
      ? this.checkDateExists(SelectedStateValue?.downersBreakSelectedDate)
      : !this.checkDateExists(SelectedStateValue?.downersBreakSelectedDate);
    if(isExisting) {
      selectedState =
        DownersResponses?.[Object.keys(responseJson?.responses)[0]]?.SelectedReduceState?.selectedState;
      SelectedStateValue =
        DownersResponses?.[Object.keys(responseJson?.responses)[0]]?.selected_value?.selectedValue;
    }
    const SelectedDownersMetricValue = SelectedStateValue?.downersMetricValue;
      if(selectedState){
        this.setState({
          downersReduceState: selectedState,
        });
      }
    if (selectedState == "downersReduceHowMuch") {
      this.setState({
        downersMetricValue: SelectedDownersMetricValue,
      });
      if (SelectedDownersMetricValue == "ounces") {
        this.setState({
          lastWeekDownersOuncesCount:
            SelectedStateValue?.lastWeekDownersOuncesCount,
          nextweekDownersOuncessReduceCount:
            SelectedStateValue?.nextweekDownersOuncessReduceCount,
        });
      } else if (SelectedDownersMetricValue == "dollars") {
        this.setState({
          lastWeekDownersDollarsTextValue:
            SelectedStateValue?.lastWeekDownersDollarsTextValue,
          nextweekDownersDollarsReduceTextValue:
            SelectedStateValue?.nextweekDownersDollarsReduceTextValue,
        });
      } else if (SelectedDownersMetricValue == "hits") {
        this.setState({
          lastWeekDownersHitsCount:
            SelectedStateValue?.lastWeekDownersHitsCount,
          nextweekDownersHitsReduceCount:
            SelectedStateValue?.nextweekDownersHitsReduceCount,
        });
      }
    } else if (selectedState == "downersReduceHowOften") {
      this.setState({
        pastSevenDaysDownersCount:
          SelectedStateValue?.pastSevenDaysDownersCount,
        nextSevenDaysDownersCount:
          SelectedStateValue?.nextSevenDaysDownersCount,
      });
    } else if (selectedState == "downersStopCompletely") {
      this.setState({
        isTakingBreakDowners: SelectedStateValue?.isTakingBreakDowners,
        downersBreakSelectedDate:
          this.checkDateExists(SelectedStateValue?.downersBreakSelectedDate) ? moment(SelectedStateValue?.downersBreakSelectedDate).format("MM/DD/YYYY") : "OtherDay",
        downersCalendarSelectedDate:
          checkDateDowners ? moment(SelectedStateValue?.downersBreakSelectedDate).format("MM/DD/YYYY") : SelectedStateValue?.downersCalendarSelectedDate,
      });
    } else if (selectedState == "Others") {
      this.setState({
        DownersOthersTextValue: SelectedStateValue?.DownersOthersTextValue,
      });
    }
  }
  updateAlcohol=(data:any)=>{
    if(data=='Substance goal response saved successfully' || data === "Substance goal response updated successfully"){
    
        localStorage.setItem("currentValue","alcohol")
        this.props.navigation.navigate("SubstanceGoals")  
      
    }
  }
  updateOtherData=(data:any)=>{
    if(data=='Substance goal response saved successfully'){
        localStorage.setItem("currentValue","Others")
        this.props.navigation.navigate("SubstanceGoals")
    }
  }
  alcoholSafer=(value:any)=>{
    if(value==='Yes'){
      this.props.navigation.navigate("SaferUserStrategies")
    }
  }
  saferHandleValue=(value:any)=>{
    if(value==='Yes'){
      this.props.navigation.navigate("SaferUserStrategies")
    }
  }
  updateShareData=(data:any)=>{
    if(data=='Substance goal response saved successfully' || data === "Substance goal response updated successfully"){
    
        localStorage.setItem("currentValue","cannabis")
        this.props.navigation.navigate("SubstanceGoals")
    }
  }

  async componentDidMount() {
    const answer=localStorage.getItem("selectedTab")
    const selectedCardId = await getStorageData("selectedCardId");
    const newData = await getStorageData("newData");
    const selectedSubstance = await getStorageData("selectedSubstance");
    setTimeout(() => {
    this.scrollRef.current &&
      this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);

    if(answer!=null && answer!=''){
    this.setState({selectedTab:answer})
    }
    if(selectedCardId) {
      this.fetchExistingSubstanceGoalData();
    } else {
      this.getCatergoriesValue();
    }
    const today = dayjs();
    const upcomingDates = Array.from({ length: 5 }, (_, index) =>
      today.add(index, "day")
    );
    this.setState({
      tobaccoBreakDay: upcomingDates,
      selectedCardId,
      categoryNameStore: selectedSubstance ? selectedSubstance.split(",") : [],
      newData: newData ? true : false,
    });
    this.setState({
      userName: localStorage.getItem("username")
    })
    window.addEventListener("keydown", this.handleKeyDown);
  }
  async componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent) {
    const focusedElement = document.activeElement as HTMLElement;
    const modalContent = document.getElementById("modal-content");
    if (
      focusedElement &&
      focusedElement.tagName === "INPUT" &&
      focusedElement.getAttribute("type") === "radio"
    ) {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();
      }
    }
    if (event.key === "ArrowDown" && modalContent) {
      modalContent.scrollBy({ top: 100, behavior: "smooth" });
    } else if (event.key === "ArrowUp" && modalContent) {
      modalContent.scrollBy({ top: -100, behavior: "smooth" });
    }
  }
  getCatergoriesValue = () => {
    let tokenHandle = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: tokenHandle
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryValueExtrat = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryShowPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleCountChange = (key: keyof S, value: number) => {
    this.setState((prevState) => {
      const currentCount = prevState[key] as number;
      if (currentCount === 0 && value < 0) {
        return null;
      }

      return {
        [key]: currentCount + value,
      } as unknown as Pick<S, keyof S>;
    });
  };

 

  handleCigarettesReduceTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    this.setState({ [name]: value } as Pick<S, "cigarettesReduceType">);
    this.setState(prevState => {
      return {
        selectedValueOption: event.target.value,
        tobaccoBreakSelectedDate: prevState.isTakingBreakCigarettes === "false" ? "": prevState.tobaccoBreakSelectedDate,
        uppersBreakSelectedDate: prevState.isTakingBreakUppers === "no" ? "": prevState.uppersBreakSelectedDate,
        downersBreakSelectedDate: prevState.isTakingBreakDowners === "no" ? "": prevState.downersBreakSelectedDate,
      }
    });
  };
  handleTobaccoBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ tobaccoBreakSelectedDate: event.target.value, hideDateError: event.target.value === "OtherDay" ? true : false });
    if(this.state.tobaccoBreakSelectedDate !== 'OtherDay'){
      this.setState({tobaccoCalendarSelectedDate:''})
    }
  };
  handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({
      selectedTab: newValue,
      tobacoMeasureType: "Cigarettes",
      tobacoReduceState: "reduceHowMuch",
      cigarettesReduceType: "numberOfCigarettes",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      isTakingBreakCigarettes: "",
      tobaccoBreakSelectedDate: "",
      tobaccoCalendarSelectedDate: '',
      tobaccoOtherMeasureTypeTextValue: "",
      tobaccoreduceHowMuchTextValue: "",
      tobaccoOthersTextValue: "",
      upperReduceState: "UppersReduceHowMuch",
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      isTakingBreakUppers: "",
      uppersBreakSelectedDate: "",
      uppersCalendarSelectedDate: '',
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      uppersOthersTextValue: "",
      downersReduceState: "downersReduceHowMuch",
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "",
      downersBreakSelectedDate: "",
      downersCalendarSelectedDate: '',
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      DownersOthersTextValue:'',
      selecteTobaccodDate:'',
      selectedUppersDate:'',
      selectedDownersDate:'',
      selectedValue: "Reduce how much",
      selectedValueOption: "",
      drinkCount: 0,
      drinkCountSecond: 0,
      drinkCountOften: 0,
      drinkCountOftenSecond: 0,
      selectedDate: "",
      selectedValueSubOption: "Ounces",
      cannabisCount: 0,
      CannabisSecond: 0,
      formattedDateInter: "",
      OthersResponse: "",
      textdollars: 0,
      textdollarssecond: 0,
    });
  };

  handleUppersBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ uppersBreakSelectedDate: event.target.value, hideDateError: event.target.value === "OtherDay" ? true : false });
    if(this.state.uppersBreakSelectedDate !== 'OtherDay'){
      this.setState({uppersCalendarSelectedDate:''})
    }
  };


  TobaccoPostAPiCall = async () => {
    if(this.state.allowTobaccoPostApiCall){ 
      localStorage.setItem("selectedHeading",'Tobacco or Nicotine')
      const selectedCardId = await getStorageData("selectedCardId");
      const header = {
        "Content-Type": configJSON.UppersApiContentType,
        token: localStorage.getItem("token"),
      };
      const selectedTobacoReduceState = this.state.tobacoReduceState;
      const tobacoMeasureType = this.state.tobacoMeasureType;
      const cigarettesReduceType = this.state.cigarettesReduceType;  
      localStorage.setItem("value10",this.state.tobaccoOtherMeasureTypeTextValue)
      localStorage.setItem("value11",tobacoMeasureType)
      let selectedValue;
      if (selectedTobacoReduceState == "reduceHowMuch") {
        if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "numberOfCigarettes") {
          localStorage.setItem("selectedSubValue",'Reduce how much')
          localStorage.setItem("staticData",'No')
          localStorage.setItem("value7",String(this.state.lastWeekCigarettesCount))
          localStorage.setItem("value8",String(this.state.nextweekCigarettesReduceCount))


          selectedValue = {
            tobacoMeasureType: this.state.tobacoMeasureType,
            cigarettesReduceType: this.state.cigarettesReduceType,
            lastWeekCigarettesCountQuestion:
              "Over the last week, how many Cigarettes did you have on a typical day?",
              lastWeekCigarettesCount: this.state.lastWeekCigarettesCount,
              nextweekCigarettesReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
              nextweekCigarettesReduceCount:
              this.state.nextweekCigarettesReduceCount,
          };
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "numberOfCigarettes") {
          localStorage.setItem("staticData",'No')
          localStorage.setItem("value7",String(this.state.lastWeekCigarettesCount))
          localStorage.setItem("value8",String(this.state.nextweekCigarettesReduceCount))
          selectedValue = {
            tobacoMeasureType: this.state.tobacoMeasureType,
            tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
            cigarettesReduceType: this.state.cigarettesReduceType,
            lastWeekCigarettesCountQuestion:
              "Over the last week, how many Cigarettes did you have on a typical day?",
              lastWeekCigarettesCount: this.state.lastWeekCigarettesCount,
              nextweekCigarettesReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
              nextweekCigarettesReduceCount:
              this.state.nextweekCigarettesReduceCount,
          };
        } else if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "Others") {
          localStorage.setItem("staticData",'Yes')
          localStorage.setItem("value7",'Reduce your typical consuming quantity')
          localStorage.setItem("value8",'')
         
          selectedValue = {
            tobacoMeasureType: this.state.tobacoMeasureType,
            cigarettesReduceType: this.state.cigarettesReduceType,
            tobaccoreduceHowMuchTextValue: this.state.tobaccoreduceHowMuchTextValue,        
          };
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "Others") {
          localStorage.setItem("staticData",'Yes')
          localStorage.setItem("value7",'Reduce your typical consuming quantity')
          localStorage.setItem("value8",'')
          selectedValue = {
            tobacoMeasureType: this.state.tobacoMeasureType,
            tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
            cigarettesReduceType: this.state.cigarettesReduceType,
            tobaccoreduceHowMuchTextValue: this.state.tobaccoreduceHowMuchTextValue, 
          };
        }
      } else if (selectedTobacoReduceState == "reduceHowOften") {
        localStorage.setItem("selectedSubValue",'Reduce how often')

        localStorage.setItem("value7",String(   this.state.pastSevenDaysCigarettesCount))
        localStorage.setItem("value8",String(  this.state.nextSevenDaysCigarettesCount))
       

        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
          pastSevenDaysCigarettesCountQuestion:
            "Out of the past 7 days, how many days did you use [what they entered in supplement ex. cigarettes, vapes, etc]",
            pastSevenDaysCigarettesCount: this.state.pastSevenDaysCigarettesCount,
            nextSevenDaysCigarettesCountQuestion:
            "Out of the NEXT 7 days, how many days do you want to use [what they entered in supplement]?",
            nextSevenDaysCigarettesCount: this.state.nextSevenDaysCigarettesCount,        
        };
      } else if (selectedTobacoReduceState == "stopCompletely") {


        localStorage.setItem("selectedSubValue",'Stop completely')

        localStorage.setItem("value7",String(this.state.tobaccoBreakSelectedDate === "OtherDay" ? this.state.tobaccoCalendarSelectedDate : this.state.tobaccoBreakSelectedDate))
        localStorage.setItem("value8",'')
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
          isTakingBreakCigarettesQuestion:"This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from [what they entered in supplement] at this point?",
          isTakingBreakCigarettes: this.state.isTakingBreakCigarettes,
          tobaccoBreakSelectedDateQuestion:
            "Way to commit to taking a break from [what they mentioned in supplement]! When day this week do you want to start?",
          tobaccoBreakSelectedDate: this.state.tobaccoBreakSelectedDate,
          tobaccoCalendarSelectedDateQuestion:
            "Selected Tobacco stop completely calendar Date",
          tobaccoCalendarSelectedDate: this.state.tobaccoCalendarSelectedDate,
        };
      } else if (selectedTobacoReduceState == "Others") {

        localStorage.setItem("selectedSubValue",'Other')

        localStorage.setItem("value7",String(   this.state.tobaccoOthersTextValue))
        localStorage.setItem("value8",'')
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
          tobaccoOthersTextValueQuestion:
            "What do you want to see happen with your use?",
          tobaccoOthersTextValue: this.state.tobaccoOthersTextValue,
        };
      }
      else {
        localStorage.setItem("selectedSubValue",'Safer/healthier')
         selectedValue={ 
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,}
        }

   const answer=this.state.tobacoReduceState
      const httpBody = {
        substance_goal_response: {
          category_name: "tobaccoOrNicotine",
          responses:
          {[answer]: {
            SelectedReduceState: {
              selectedState: this.state.tobacoReduceState,
            },
            selected_value: {
              selectedValue: selectedValue,
            },
          }}
        },
      };
  
      if(selectedCardId) {
        const TobaccorequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.putSubstanceTobaccoApiCallId = TobaccorequestMessage.messageId;
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        runEngine.sendMessage(TobaccorequestMessage.id, TobaccorequestMessage);
        return true;
      } else {
        const TobaccorequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postTobaccoApiCallId = TobaccorequestMessage.messageId;
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.TobaccoAPiEndPoints
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        TobaccorequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.SubstanceAPiMethod
        );
        runEngine.sendMessage(TobaccorequestMessage.id, TobaccorequestMessage);
        removeStorageData("selectedCardId");
        removeStorageData("newData");
        removeStorageData("firstSelectedCardId");
        removeStorageData("firstNewData");
        return true;
      }
    }
  }

checkTobaccoReduceHowMuchRequired = (allowTobaccoPostApiCall: boolean, tobaccoOtherMeasureTypeTextValue: string, tobacoMeasureType: string, isTextValid: (textValue: string | any[]) => boolean | "", cigarettesReduceType: string, tobaccoreduceHowMuchTextValue: string) => {
  const isValidTobaccoreduceHowMuchTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoreduceHowMuchTextValue);
  const isValidTobaccoOtherMeasureTypeTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoOtherMeasureTypeTextValue);

 
  if (tobacoMeasureType === 'Others'  && cigarettesReduceType == 'Others') {
    if (isTextValid(tobaccoOtherMeasureTypeTextValue) && !isValidTobaccoOtherMeasureTypeTextValue && isTextValid(tobaccoreduceHowMuchTextValue) && !isValidTobaccoreduceHowMuchTextValue ) {
      
      allowTobaccoPostApiCall = true;

    }
  } else if (tobacoMeasureType === 'Cigarettes' && cigarettesReduceType == 'Others'){
    if (isTextValid(tobaccoreduceHowMuchTextValue) && !isValidTobaccoreduceHowMuchTextValue ) {
      allowTobaccoPostApiCall = true;
    }
  } else if (tobacoMeasureType == 'Others' && cigarettesReduceType == 'numberOfCigarettes'){
    if (isTextValid(tobaccoOtherMeasureTypeTextValue) && !isValidTobaccoOtherMeasureTypeTextValue && this.state.lastWeekCigarettesCount >0) {
      allowTobaccoPostApiCall = true;
    }
  }else if (tobacoMeasureType == 'Cigarettes' && cigarettesReduceType == 'numberOfCigarettes'){
    if (this.state.lastWeekCigarettesCount > 0 && this.state.lastWeekCigarettesCount >= this.state.nextweekCigarettesReduceCount) {
      allowTobaccoPostApiCall = true;
    }
  } else{
    allowTobaccoPostApiCall = true;
  }
  this.setState({ allowTobaccoPostApiCall }, () => {
    this.TobaccoPostAPiCall();
  });
}


  checkAllTobaccoRequired = () => {
    const {tobacoReduceState,tobaccoBreakSelectedDate,tobaccoCalendarSelectedDate,tobacoMeasureType,tobaccoOthersTextValue,cigarettesReduceType,tobaccoOtherMeasureTypeTextValue,tobaccoreduceHowMuchTextValue} = this.state
    let allowTobaccoPostApiCall = false;
    const isValidTobaccoOthersTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoOthersTextValue);
    const isValidTobaccoOtherMeasureTypeTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoOtherMeasureTypeTextValue);
    const isTextValid = (textValue: string | any[]) => textValue && textValue.length >= 1;
    switch (tobacoReduceState) {
      case 'reduceHowMuch':
       this.checkTobaccoReduceHowMuchRequired(allowTobaccoPostApiCall,
        tobaccoOtherMeasureTypeTextValue,tobacoMeasureType,isTextValid,cigarettesReduceType,tobaccoreduceHowMuchTextValue)
        break;
      case 'stopCompletely':
      
        if(this.state.tobacoMeasureType ==='Others' && this.state.tobaccoOtherMeasureTypeTextValue!='' && 
          !isValidTobaccoOtherMeasureTypeTextValue || this.state.tobacoMeasureType==='Cigarettes'  ){
            if (this.state.isTakingBreakCigarettes !== "" && tobaccoBreakSelectedDate === 'OtherDay' && tobaccoCalendarSelectedDate?.length > 2) {
              allowTobaccoPostApiCall = true;
              this.setState({ hideDateError: true });
            } else if(this.state.isTakingBreakCigarettes !== "" && tobaccoBreakSelectedDate !== 'OtherDay' && this.state.tobaccoBreakSelectedDate !== "" ){
              allowTobaccoPostApiCall = true;
            }
        this.setState({ allowTobaccoPostApiCall }, () => {
          this.TobaccoPostAPiCall();
        });
      }
        break;
  
      case 'reduceHowOften':
        if(this.state.tobacoMeasureType ==='Others' && this.state.tobaccoOtherMeasureTypeTextValue!='' && 
          !isValidTobaccoOtherMeasureTypeTextValue || this.state.tobacoMeasureType==='Cigarettes'  ){
        if (this.state.pastSevenDaysCigarettesCount > 0 && this.state.pastSevenDaysCigarettesCount >= this.state.nextSevenDaysCigarettesCount) {
          allowTobaccoPostApiCall = true;
        }  
        this.setState({ allowTobaccoPostApiCall }, () => {
          this.TobaccoPostAPiCall();
        });
          }
        break;
    
      case 'Others':
        if(this.state.tobacoMeasureType ==='Others' && this.state.tobaccoOtherMeasureTypeTextValue!='' && 
          !isValidTobaccoOtherMeasureTypeTextValue || this.state.tobacoMeasureType==='Cigarettes'  ){
        if (isTextValid(tobaccoOthersTextValue) && !isValidTobaccoOthersTextValue) {
          allowTobaccoPostApiCall = true;
        }
        this.setState({ allowTobaccoPostApiCall }, () => {
          this.TobaccoPostAPiCall();
        });
      }   
        break;
      default :
      allowTobaccoPostApiCall = true;
      this.setState({ allowTobaccoPostApiCall }, () => {
        this.TobaccoPostAPiCall();
      });
  }
  }

  moveToUppers = () => {
    this.setState({
      TobaccoButtonCalled:true,
      hideDateError: false,
    })
    this.checkAllTobaccoRequired()

  };

  async substanceGoals() {
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const firstNewData = await getStorageData("firstNewData");
    setStorageData("selectedCardId", firstSelectedCardId);
    setStorageData("newData", firstNewData);
    const selectedCardId = await getStorageData("selectedCardId");
    const newData = await getStorageData("newData");
    const selectedHeading = localStorage.getItem("selectedHeading");
    const valuesSequence = localStorage.getItem("valuesSequence");
    const isLastGoal = valuesSequence && valuesSequence.split(",").indexOf(selectedHeading || "") === 0;
    if(!newData && selectedCardId) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "GoalsInformation");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      removeStorageData("selectedCardId");
    } else if (firstNewData && firstSelectedCardId) {
      if(isLastGoal) {
        removeStorageData("selectedCardId");
        removeStorageData("newData");
        removeStorageData("firstSelectedCardId");
        removeStorageData("firstNewData");
        const lastMsg = new Message(getName(MessageEnum.NavigationMessage));
        lastMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Goals");
        lastMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(lastMsg);
      } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "SubstanceGoals");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
      }
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "Goals");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }
  hamburgerMenu() {
    localStorage.setItem("pageNavigate","CustomForm")
    this.props.navigation.navigate("HamburgerMenu");
  }
  SaferHandle(){
    this.props.navigation.navigate("SaferUserStrategies");
  }
  handleDownersBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ downersBreakSelectedDate: event.target.value, hideDateError: event.target.value === "OtherDay" ? true : false });
    if(this.state.downersBreakSelectedDate !== 'OtherDay'){
      this.setState({downersCalendarSelectedDate:''})
    }
  };

  TobaccoGetApiCall() {

    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getTobaccoApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTobaccoApiCallId = getTobaccoApiCall.messageId;

    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TobaccoGetApiendPoint
    );

    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getTobaccoApiCall.id, getTobaccoApiCall);
  }

  UppersGetApiCall() {
    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getUppersApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUppersApiCallId = getUppersApiCall.messageId;

    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UppersGetApiendPoint
    );

    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getUppersApiCall.id, getUppersApiCall);
  }

  DownersGetApiCall() {
    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getDownersApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDownersApiCallId = getDownersApiCall.messageId;

    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DownersGetApiendPoint
    );

    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getDownersApiCall.id, getDownersApiCall);
  }



  UppersPostAPiCall = async () => {
    if(this.state.allowUppersPostApiCall){ 
      localStorage.setItem("selectedHeading",'Uppers')
      const selectedCardId = await getStorageData("selectedCardId");
      const header = {
        "Content-Type": configJSON.UppersApiContentType,
        token: localStorage.getItem("token"),
      };
      const selectedUppersState = this.state.upperReduceState;
  
      const upperMetricValue = this.state.upperMetricValue;
  
      let selectedValue;
      
      if (selectedUppersState == "UppersReduceHowMuch") {
        localStorage.setItem("selectedSubValue",'Reduce how much')
        if (upperMetricValue == "ounces") {
          localStorage.setItem("value4",String( this.state.lastWeekUppersOuncesCount))
            localStorage.setItem("value5",String(this.state.nextweekUppersOuncessReduceCount))
            localStorage.setItem("value6",'ounces')
          selectedValue = {
            upperMetricValue: this.state.upperMetricValue,
            lastWeekUppersOuncesCountQuestion:
              "Over the last week, how many Ounces did you have on a typical day when you use?",
              lastWeekUppersOuncesCount: this.state.lastWeekUppersOuncesCount,
            nextweekUppersOuncessReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
            nextweekUppersOuncessReduceCount:
              this.state.nextweekUppersOuncessReduceCount,
          };
        } else if (upperMetricValue == "dollars") {
          localStorage.setItem("value4",String(   this.state.lastWeekUppersDollarsTextValue))
            localStorage.setItem("value5",String(  this.state.nextweekUppersDollarsReduceTextValue))
            localStorage.setItem("value6",'dollars')
          selectedValue = {
            upperMetricValue: this.state.upperMetricValue,
            lastWeekUppersDollarsTextValueQuestion:
              "Over the last week, how many $$ did you have on a typical day when you use?",
            lastWeekUppersDollarsTextValue:
              this.state.lastWeekUppersDollarsTextValue,
            nextweekUppersDollarsReduceTextValueQuestion:
              "What would you like to reduce that amount to this next week?What is your goal?",
            nextweekUppersDollarsReduceTextValue:
              this.state.nextweekUppersDollarsReduceTextValue,
          };
        } else if (upperMetricValue == "hits") {
          localStorage.setItem("value4",String(   this.state.lastWeekUppersHitsCount))
            localStorage.setItem("value5",String(  this.state.nextweekUppersHitsReduceCount))
            localStorage.setItem("value6",'hits')
          selectedValue = {
            upperMetricValue: this.state.upperMetricValue,
            lastWeekUppersHitsCountQuestion:
              "Over the last week, how many Hits did you have on a typical day when you use?",
            lastWeekUppersHitsCount: this.state.lastWeekUppersHitsCount,
            nextweekUppersHitsReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
            nextweekUppersHitsReduceCount:
              this.state.nextweekUppersHitsReduceCount,
          };
        }
      } else if (selectedUppersState == "UppersReduceHowOften") {
    
        localStorage.setItem("selectedSubValue",'Reduce how often')
        localStorage.setItem("value4",String(   this.state.pastSevenDaysUppersCount))
        localStorage.setItem("value5",String(  this.state.nextSevenDaysUppersCount))
        localStorage.setItem("value6",'')
        selectedValue = {
          pastSevenDaysUppersCountQuestion:
            "Out of the past 7 days, how many days did you use uppers?",
          pastSevenDaysUppersCount: this.state.pastSevenDaysUppersCount,
          nextSevenDaysUppersCountQuestion:
            "Out of the NEXT 7 days, how many days do you want to use uppers?",
          nextSevenDaysUppersCount: this.state.nextSevenDaysUppersCount,
        };
      } else if (selectedUppersState == "UppersStopCompletely") {
        localStorage.setItem("selectedSubValue",'Stop completely')
        localStorage.setItem("value4",String(this.state.uppersBreakSelectedDate === "OtherDay" ? this.state.uppersCalendarSelectedDate : this.state.uppersBreakSelectedDate))
        localStorage.setItem("value5",'')
        localStorage.setItem("value6",'')
        selectedValue = {
          isTakingBreakUppersQuestion:
            "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from using uppers at this point?",
          isTakingBreakUppers: this.state.isTakingBreakUppers,
          uppersBreakSelectedDateQuestion:
            "Way to commit to taking a break from uppers! When day this week do you want to start?",
          uppersBreakSelectedDate: this.state.uppersBreakSelectedDate,
          uppersCalendarSelectedDateQuestion:
            "Selected Uppers stop completely calendar Date",
          uppersCalendarSelectedDate: this.state.uppersCalendarSelectedDate,
        };
      } else if (selectedUppersState == "Others") {
        localStorage.setItem("selectedSubValue",'Other')
        localStorage.setItem("value4",String(   this.state.uppersOthersTextValue))
        localStorage.setItem("value5",'')
        localStorage.setItem("value6",'')
        selectedValue = {
          uppersOthersTextValueQuestion:
            "What do you want to see happen with your use?",
          uppersOthersTextValue: this.state.uppersOthersTextValue,
        };
      }
      else{
        localStorage.setItem("selectedSubValue",'Safer/Healthier')
       }

  const answer=this.state.upperReduceState
      const httpBody = {
        substance_goal_response: {
          category_name: "uppers",
          responses: {
            [answer]:{
            SelectedReduceState: {
              selectedState: this.state.upperReduceState,
            },
            selected_value: {
              selectedValue: selectedValue,
            },
          }}
        },
      };
  
      if(selectedCardId) {
        const UppersrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.putSubstanceUppersApiCallId = UppersrequestMessage.messageId;
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        runEngine.sendMessage(UppersrequestMessage.id, UppersrequestMessage);
        return true;
      } else {
        const UppersrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postUppersApiCallId = UppersrequestMessage.messageId;
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.UppersAPiEndPoints
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        UppersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.SubstanceAPiMethod
        );
        runEngine.sendMessage(UppersrequestMessage.id, UppersrequestMessage);
        removeStorageData("selectedCardId");
        removeStorageData("newData");
        removeStorageData("firstSelectedCardId");
        removeStorageData("firstNewData");
        return true;
      }
    }
  }

  checkAllUppersRequired = () => {
  const {upperReduceState,upperMetricValue,nextweekUppersDollarsReduceTextValue,lastWeekUppersDollarsTextValue , uppersBreakSelectedDate,uppersCalendarSelectedDate ,uppersOthersTextValue} = this.state
  let allowUppersPostApiCall = false;
  const isTextValid = (textValue: string | any[]) => textValue && textValue.length >= 1;
  const isValiduppersOthersText = /^[^a-zA-Z]*$/.test(this.state.uppersOthersTextValue || '');
  const isValidlastWeekUppersDollarsText = /^\d*$/.test(this.state.lastWeekUppersDollarsTextValue);
  const isValidnextweekUppersDollarsText = /^\d*$/.test(this.state.nextweekUppersDollarsReduceTextValue);
switch (upperReduceState) {
  case 'UppersReduceHowMuch':
    if (upperMetricValue === 'dollars') {
      allowUppersPostApiCall = this.checkReduceHowMuchUppersDollars(nextweekUppersDollarsReduceTextValue, isValidlastWeekUppersDollarsText, isValidnextweekUppersDollarsText, lastWeekUppersDollarsTextValue, isTextValid, allowUppersPostApiCall);
    }else if (upperMetricValue === 'ounces') {
      allowUppersPostApiCall = this.checkReduceHowMuchUppersOunce(allowUppersPostApiCall);
    } else if (upperMetricValue === 'hits') {
      allowUppersPostApiCall = this.checkReduceHowMuchUppersHits(allowUppersPostApiCall);
    }
    break;

  case 'UppersStopCompletely':
    if (this.state.isTakingBreakUppers !== "" && uppersBreakSelectedDate === 'OtherDay' && uppersCalendarSelectedDate?.length > 2) {
      allowUppersPostApiCall = true;
      this.setState({ hideDateError: true });
    } else if(this.state.isTakingBreakUppers !== "" && uppersBreakSelectedDate !== 'OtherDay' && uppersBreakSelectedDate !== "" ){
      allowUppersPostApiCall = true;
    }
    break;
  case 'UppersReduceHowOften':
      if (this.state.pastSevenDaysUppersCount > 0 && this.state.pastSevenDaysUppersCount >= this.state.nextSevenDaysUppersCount) {
        allowUppersPostApiCall = true;
      }
      break;
  case 'Others':
    allowUppersPostApiCall = this.checkOthersUppers(isTextValid, uppersOthersTextValue, isValiduppersOthersText, allowUppersPostApiCall);
    break;
  default :
  allowUppersPostApiCall = true;
}
  this.setState({ allowUppersPostApiCall }, () => {
    this.UppersPostAPiCall();
  });
}

  moveToDowners = () => {
  this.setState({
    UppersButtonCalled:true,
    hideDateError: false,
  })
  this.checkAllUppersRequired()
  };

  DownersPostAPiCall = async () => {
    localStorage.setItem("selectedHeading",'Downers')
    const selectedCardId = await getStorageData("selectedCardId");
    if (this.state.allowDownersPostApiCall) {
      const header = {
        "Content-Type": configJSON.UppersApiContentType,
        token: localStorage.getItem("token"),
      };
      const selectedDownersState = this.state.downersReduceState;
      const downersMetricValue = this.state.downersMetricValue;
      let selectedValue;
      if (selectedDownersState == "downersReduceHowMuch") {
        localStorage.setItem("selectedSubValue",'Reduce how much')
        if (downersMetricValue == "ounces") {
          localStorage.setItem("value4",String(   this.state.lastWeekDownersOuncesCount))
            localStorage.setItem("value5",String(  this.state.nextweekDownersOuncessReduceCount))
            localStorage.setItem("value6",'ounces')

          selectedValue = {
            downersMetricValue: this.state.downersMetricValue,
            lastWeekDownersOuncesCountQuestion:
              "Over the last week, how many Ounces did you have on a typical day when you use?",
            lastWeekDownersOuncesCount: this.state.lastWeekDownersOuncesCount,
            nextweekDownersOuncessReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
            nextweekDownersOuncessReduceCount:
              this.state.nextweekDownersOuncessReduceCount,
          };
        } else if (downersMetricValue == "dollars") {
          localStorage.setItem("value4",String(   this.state.lastWeekDownersDollarsTextValue))
          localStorage.setItem("value5",String(  this.state.nextweekDownersDollarsReduceTextValue))
          localStorage.setItem("value6",'dollars')
          selectedValue = {
            downersMetricValue: this.state.downersMetricValue,
            lastWeekDownersDollarsTextValueQuestion:
              "Over the last week, how many $$ did you have on a typical day when you use?",
            lastWeekDownersDollarsTextValue:
              this.state.lastWeekDownersDollarsTextValue,
            nextweekDownersDollarsReduceTextValueQuestion:
              "What would you like to reduce that amount to this next week?What is your goal?",
            nextweekDownersDollarsReduceTextValue:
              this.state.nextweekDownersDollarsReduceTextValue,
          };
        } else if (downersMetricValue == "hits") {
          localStorage.setItem("value4",String(   this.state.lastWeekDownersHitsCount))
          localStorage.setItem("value5",String(  this.state.nextweekDownersHitsReduceCount))
          localStorage.setItem("value6",'hits')
          selectedValue = {
            downersMetricValue: this.state.downersMetricValue,
            lastWeekDownersHitsCountQuestion:
              "Over the last week, how many Hits did you have on a typical day when you use?",
            lastWeekDownersHitsCount: this.state.lastWeekDownersHitsCount,
            nextweekDownersHitsReduceCountQuestion:
              "What would you like to reduce that amount to this next week?",
            nextweekDownersHitsReduceCount:
              this.state.nextweekDownersHitsReduceCount,
          };
        }
      } else if (selectedDownersState == "downersReduceHowOften") {

        localStorage.setItem("selectedSubValue",'Reduce how often')
        localStorage.setItem("value4",String(   this.state.pastSevenDaysDownersCount))
        localStorage.setItem("value5",String(  this.state.nextSevenDaysDownersCount))
        localStorage.setItem("value6",'')
        selectedValue = {
          pastSevenDaysDownersCountQuestion:
            "Out of the past 7 days, how many days did you use downers?",
          pastSevenDaysDownersCount: this.state.pastSevenDaysDownersCount,
          nextSevenDaysDownersCountQuestion:
            "Out of the NEXT 7 days, how many days do you want to use downers?",
          nextSevenDaysDownersCount: this.state.nextSevenDaysDownersCount,
        };
      } else if (selectedDownersState == "downersStopCompletely") {

        localStorage.setItem("selectedSubValue",'Stop completely')
        localStorage.setItem("value4",String(this.state.downersBreakSelectedDate === "OtherDay" ? this.state.downersCalendarSelectedDate : this.state.downersBreakSelectedDate))
        localStorage.setItem("value5",'')
        localStorage.setItem("value6",'')
        selectedValue = {
          isTakingBreakDownersQuestion:
            "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from using downers at this point?",
          isTakingBreakDowners: this.state.isTakingBreakDowners,
          downersBreakSelectedDateQuestion:
            "Way to commit to taking a break from your downers use! When day this week do you want to start?",
          downersBreakSelectedDate: this.state.downersBreakSelectedDate,
          downersCalendarSelectedDateQuestion:
            "Selected Downers stop completely calendar Date",
          downersCalendarSelectedDate: this.state.downersCalendarSelectedDate,
        };
      } else if (selectedDownersState == "Others") {
        localStorage.setItem("selectedSubValue",'Other')
        localStorage.setItem("value4",String(   this.state.DownersOthersTextValue))
        localStorage.setItem("value5",'')
        localStorage.setItem("value6",'')
        selectedValue = {
          DownersOthersTextValueQuestion:
            "What do you want to see happen with your use?",
          DownersOthersTextValue: this.state.DownersOthersTextValue,
        };
      }
      else {
        localStorage.setItem("selectedSubValue",'Safer/healthier')
      }

      const answer=this.state.downersReduceState
      const httpBody = {
        substance_goal_response: {
          category_name: "downers",
          responses: {
            [answer]:{
            SelectedReduceState: {
              selectedState: this.state.downersReduceState,
            },
            selected_value: {
              selectedValue: selectedValue,
            },
          }}
        },
      };

      if(selectedCardId) {
        const DownersrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.putSubstanceDownersApiCallId = DownersrequestMessage.messageId;
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        runEngine.sendMessage(DownersrequestMessage.id, DownersrequestMessage);
        return true;
      } else {
        const DownersrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postDownersApiCallId = DownersrequestMessage.messageId;
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.DownersAPiEndPoints
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        DownersrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.SubstanceAPiMethod
        );
        runEngine.sendMessage(DownersrequestMessage.id, DownersrequestMessage);
        removeStorageData("selectedCardId");
        removeStorageData("newData");
        removeStorageData("firstSelectedCardId");
        removeStorageData("firstNewData");
        return true;
      }
    }
  }


  checkAllDownersRequired = () => {
    const {
      downersReduceState,
      downersMetricValue,     
      downersBreakSelectedDate,
      downersCalendarSelectedDate,
      lastWeekDownersDollarsTextValue,
      nextweekDownersDollarsReduceTextValue,
      DownersOthersTextValue,   
     } = this.state;
  let allowDownersPostApiCall = false;
  const isTextValid = (textValue: string | any[]) => textValue && textValue.length >= 1;
  const isValidDownersLastWeekText = /^\d*$/.test(this.state.lastWeekDownersDollarsTextValue);
  const isValidDownersNextweekText = /^\d*$/.test(this.state.nextweekDownersDollarsReduceTextValue);
  const isValidDownersOthersText = /^[^a-zA-Z]*$/.test(this.state.DownersOthersTextValue);

switch (downersReduceState) {
  case 'downersReduceHowMuch':
    if (downersMetricValue === 'dollars') {
      allowDownersPostApiCall = this.checkReduceHowMuchDownersDollars(lastWeekDownersDollarsTextValue, isValidDownersLastWeekText, isValidDownersNextweekText, nextweekDownersDollarsReduceTextValue, isTextValid, allowDownersPostApiCall);
    } else if (downersMetricValue === 'ounces') {
      allowDownersPostApiCall = this.checkReduceHowMuchDownersOunce(allowDownersPostApiCall);
    } else if (downersMetricValue === 'hits') {
      allowDownersPostApiCall = this.checkReduceHowMuchDownersHits(allowDownersPostApiCall);
    }
    break;

  case 'downersStopCompletely':
    if (this.state.isTakingBreakDowners !== "" && downersBreakSelectedDate === 'OtherDay' && downersCalendarSelectedDate?.length >2) {
      allowDownersPostApiCall = true;
      this.setState({ hideDateError: true });
    } else if(this.state.isTakingBreakDowners !== "" && downersBreakSelectedDate !== 'OtherDay' && downersBreakSelectedDate !== "" ){
      allowDownersPostApiCall = true;
    }
    break;

  case 'downersReduceHowOften':
    if (this.state.pastSevenDaysDownersCount > 0 && this.state.pastSevenDaysDownersCount >= this.state.nextSevenDaysDownersCount) {
      allowDownersPostApiCall = true;
    }
    break;
  
  case 'Others':
    allowDownersPostApiCall = this.checkOthersDowners(isTextValid, DownersOthersTextValue, isValidDownersOthersText, allowDownersPostApiCall);
    break;
  default :
  allowDownersPostApiCall = true;
}
  this.setState({ allowDownersPostApiCall }, () => {
    this.DownersPostAPiCall();
  });
}

checkReduceHowMuchDownersDollars = (lastWeekDownersDollarsTextValue: string, isValidDownersLastWeekText: any, isValidDownersNextweekText: any, nextweekDownersDollarsReduceTextValue: string, isTextValid: any, allowDownersPostApiCall: boolean) => {
  if (isTextValid(lastWeekDownersDollarsTextValue)&& isValidDownersLastWeekText && isValidDownersNextweekText && isTextValid(nextweekDownersDollarsReduceTextValue) && Number(this.state.lastWeekDownersDollarsTextValue) >= Number(this.state.nextweekDownersDollarsReduceTextValue)) {
    allowDownersPostApiCall = true;
  }
  return allowDownersPostApiCall;
};

checkReduceHowMuchDownersOunce = (allowDownersPostApiCall: boolean) => {
  if (this.state.lastWeekDownersOuncesCount > 0 && this.state.lastWeekDownersOuncesCount >= this.state.nextweekDownersOuncessReduceCount) {
    allowDownersPostApiCall = true;
  }
  return allowDownersPostApiCall;
};

checkReduceHowMuchDownersHits = (allowDownersPostApiCall: boolean) => {
  if (this.state.lastWeekDownersHitsCount > 0 && this.state.lastWeekDownersHitsCount >= this.state.nextweekDownersHitsReduceCount) {
    allowDownersPostApiCall = true;
  }
  return allowDownersPostApiCall;
};

checkOthersDowners = (isTextValid: any, DownersOthersTextValue: string, isValidDownersOthersText: boolean, allowDownersPostApiCall: boolean) => {
  if (isTextValid(DownersOthersTextValue) && !isValidDownersOthersText) {
    allowDownersPostApiCall = true;
  }
  return allowDownersPostApiCall;
};

checkReduceHowMuchUppersDollars = (nextweekUppersDollarsReduceTextValue: string, isValidlastWeekUppersDollarsText: any, isValidnextweekUppersDollarsText: any, lastWeekUppersDollarsTextValue: string, isTextValid: any, allowUppersPostApiCall: boolean) => {
  if (isTextValid(nextweekUppersDollarsReduceTextValue) && isValidlastWeekUppersDollarsText && isValidnextweekUppersDollarsText && isTextValid(lastWeekUppersDollarsTextValue) && Number(this.state.lastWeekUppersDollarsTextValue) >= Number(this.state.nextweekUppersDollarsReduceTextValue)) {
    allowUppersPostApiCall = true;
  }
  return allowUppersPostApiCall;
};

checkReduceHowMuchUppersOunce = (allowUppersPostApiCall: boolean) => {
  if (this.state.lastWeekUppersOuncesCount  > 0 && this.state.lastWeekUppersOuncesCount >= this.state.nextweekUppersOuncessReduceCount) {
    allowUppersPostApiCall = true;
  }
  return allowUppersPostApiCall;
};

checkReduceHowMuchUppersHits = (allowUppersPostApiCall: boolean) => {
  if (this.state.lastWeekUppersHitsCount > 0 && this.state.lastWeekUppersHitsCount >= this.state.nextweekUppersHitsReduceCount) {
    allowUppersPostApiCall = true;
  }
  return allowUppersPostApiCall;
};

checkOthersUppers = (isTextValid: any, uppersOthersTextValue: string, isValiduppersOthersText: boolean, allowUppersPostApiCall: boolean) => {
  if (isTextValid(uppersOthersTextValue) && !isValiduppersOthersText) {
    allowUppersPostApiCall = true;
  }
  return allowUppersPostApiCall;
};
  moveToOthers = () => {
    this.setState({
      DownersButtonCalled:true,
      hideDateError: false,
    })
    this.checkAllDownersRequired()
  };



  resetTobaccoOthersTextValue = () => {
    this.setState({
      tobaccoOtherMeasureTypeTextValue: "",
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    });
  };

  resetTobaccoreduceHowMuchTextValue = () => {
    this.setState({
      tobaccoreduceHowMuchTextValue: "",
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    });
  };

  resetNumberOfCigarettesValue = () => {
    this.setState({
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    });
  };

  resetUppersDollarAndHits = () => {
    this.setState({
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };
  resetUppersOunceAndHits = () => {
    this.setState({
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetUppersDollarAndOunces = () => {
    this.setState({
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetUppersHowOften = () => {
    this.setState({
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      isTakingBreakUppers: "",
      uppersBreakSelectedDate:'',
      uppersCalendarSelectedDate: '',
      uppersOthersTextValue: "",
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };
  resetUppersSafer = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "",
      uppersBreakSelectedDate: '',
      uppersCalendarSelectedDate: '',
      uppersOthersTextValue: "",
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetUppersStopCompletely = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "",
      uppersBreakSelectedDate: '',
      uppersCalendarSelectedDate: '',
      uppersOthersTextValue: "",
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetUppersOthers = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      uppersOthersTextValue: "",
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetUppersHowMuch = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "",
      uppersBreakSelectedDate:'',
      uppersCalendarSelectedDate: '',
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      UppersButtonCalled:false,
      allowUppersPostApiCall:false
    });
  };

  resetTobaccoHowOften = () => {
this.setState({
  pastSevenDaysCigarettesCount: 0,
  nextSevenDaysCigarettesCount: 0,
  isTakingBreakCigarettes: "",
  tobaccoBreakSelectedDate: '',
  tobaccoCalendarSelectedDate: '',
  tobaccoOthersTextValue: "",
  TobaccoButtonCalled:false,
  allowTobaccoPostApiCall:false
})
  }
  resetTobaccoSafer = () => {
this.setState({
  cigarettesReduceType: "Others",
  tobaccoreduceHowMuchTextValue: "",
  lastWeekCigarettesCount: 0,
  nextweekCigarettesReduceCount: 0,
  isTakingBreakCigarettes: "",
  tobaccoBreakSelectedDate: '',
  tobaccoCalendarSelectedDate: '',
  tobaccoOthersTextValue: "",
  TobaccoButtonCalled:false,
  allowTobaccoPostApiCall:false
})
  }

  resetTobaccoStopCompletely = () => {
    this.setState({
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      isTakingBreakCigarettes: "",
      tobaccoBreakSelectedDate:  '',
      tobaccoCalendarSelectedDate: '',
      tobaccoOthersTextValue: "",
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    })
  }

  resetTobaccoOthers = () => {
    this.setState({
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      tobaccoOthersTextValue: "",
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    })
  }

  resetTobaccoHowMuch = () => {
    this.setState({
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      isTakingBreakCigarettes: "",
      tobaccoBreakSelectedDate: '',
      tobaccoCalendarSelectedDate: '',
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      TobaccoButtonCalled:false,
      allowTobaccoPostApiCall:false
    })
  }


  resetDownersDollarAndHits = () => {
    this.setState({
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersOunceAndHits = () => {
    this.setState({
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersDollarAndOunces = () => {
    this.setState({
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersHowOften = () => {
    this.setState({
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "",
      downersBreakSelectedDate: '',
      downersCalendarSelectedDate: '',
      DownersOthersTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersSafer = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      isTakingBreakDowners: "",
      downersBreakSelectedDate: '',
      downersCalendarSelectedDate: '',
      DownersOthersTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersStopCompletely = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "",
      downersBreakSelectedDate: '',
      downersCalendarSelectedDate: '',
      DownersOthersTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersOthers = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      DownersOthersTextValue:'',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }

  resetDownersHowMuch = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "",
      downersBreakSelectedDate: '',
      downersCalendarSelectedDate: '',
      DownersButtonCalled:false,
      allowDownersPostApiCall:false
    });
  }


  handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    this.setState({ [name]: value.replace(/^\s+/, ''), } as Pick<
      S,
      "lastWeekUppersDollarsTextValue"
    >);
  };

  handleDataCalendar = (newDate: any) => {
    const formattedDate = dayjs(newDate.$d).format('MM/DD/YYYY');
    this.setState({ selecteTobaccodDate: formattedDate })
  }

  handleUppersDataCalendar = (newDate: any) => {
    const formattedDate = dayjs(newDate.$d).format('MM/DD/YYYY');
    this.setState({ selectedUppersDate: formattedDate })
  }

  handleDownersDataCalendar = (newDate: any) => {
    const formattedDate = dayjs(newDate.$d).format('MM/DD/YYYY');
    this.setState({ selectedDownersDate: formattedDate })
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  closePopUp() {
    this.setState({
      openCalender: "",
      dateErrorMessage:"",
    });
  }
  openPopUp(key: number) {
    this.setState({
      openCalender: key.toString()
    });
  }
  confirmCal() {
    const currentDate = dayjs(); 
    const selectedDate = dayjs(this.state.selecteTobaccodDate);
  
    if(this.state.selecteTobaccodDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (selectedDate.isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return;
    }
    this.setState({
      formattedDateInter: selectedDate.format("MM/DD/YYYY"), 
      dateErrorMessage: "", 
    });
    this.setState({ openCalender: "" });
    const isDateIncluded = this.state.tobaccoBreakDay
      .map(date => dayjs(date).format("MM/DD/YYYY")) 
      .includes(this.state.selecteTobaccodDate); 

    if (isDateIncluded) {
      this.setState({ tobaccoBreakSelectedDate: this.state.selecteTobaccodDate });
    } else {
      this.setState({ tobaccoCalendarSelectedDate: this.state.selecteTobaccodDate });
    }
  }
  confirmUppersCalendarDate = () => {
    const currentDate = dayjs();
    const selectedUppersDate = dayjs(this.state.selectedUppersDate);
    
    if(this.state.selectedUppersDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (selectedUppersDate.isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return; 
    }
    this.setState({
      formattedDateInter: selectedUppersDate.format("MM/DD/YYYY"),
      dateErrorMessage: "", 
    });
    
    this.setState({ openCalender: "" });
  
    const isDateIncluded = this.state.tobaccoBreakDay
      .map(date => dayjs(date).format("MM/DD/YYYY"))
      .includes(this.state.selectedUppersDate);
    if (isDateIncluded) {
      this.setState({ uppersBreakSelectedDate: this.state.selectedUppersDate });
    } else {
      this.setState({ uppersCalendarSelectedDate: this.state.selectedUppersDate });
    }
  }

  confirmDownersCalendarDate = () => {
    const currentDate = dayjs(); 
    const selectedDownersDate = dayjs(this.state.selectedDownersDate);
    if(this.state.selectedDownersDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (selectedDownersDate.isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return;
    }
    this.setState({
      formattedDateInter: selectedDownersDate.format("MM/DD/YYYY"),
      dateErrorMessage: "",
    });
    
    this.setState({ openCalender: "" });
    
    const isDateIncluded = this.state.tobaccoBreakDay
      .map(date => dayjs(date).format("MM/DD/YYYY"))
      .includes(this.state.selectedDownersDate);
    if (isDateIncluded) {
      this.setState({ downersBreakSelectedDate: this.state.selectedDownersDate });
    } else {
      this.setState({ downersCalendarSelectedDate: this.state.selectedDownersDate });
    }
  }

  handleInputCountChange = (event: any) => {
    const { name, value } = event.target;
    if (!isNaN(value) && Number(value) >= 0) {
      this.setState({ [name]: Number(value) } as Pick<S, keyof S>);
    }
  };
  handleInputHowOftenCountChange = (event: any) => {
    const { name, value } = event.target;
    const numericValue = Number(value);
    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 7) {
      this.setState({ [name]: numericValue } as Pick<S, keyof S>);
    }
  };

  handleHowOftenCountChange = (key: keyof S, value: number) => {
    this.setState((prevState) => {
      const currentCount = prevState[key] as number;

      const newCount = currentCount + value;
      if (newCount < 0 || newCount > 7) {
        return null; 
      }
  
      return {
        [key]: newCount,
      } as unknown as Pick<S, keyof S>;
    });
  };
  
  checkDateExists = (date: string) => {
    const doesMatchFirstFive = this.state.tobaccoBreakDay
      .slice(0, 5)
      .some((opt: any) => opt.format("MM/DD/YYYY") === date);
    return doesMatchFirstFive;
  };
  // Customizable Area End
}
