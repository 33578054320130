import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import dayjs from 'dayjs';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  checkedItems:any,
  formattedDateInter:any,
  valueOther:string,
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet:any,
  openCalender: string,
  openTime:boolean,
  editHandle:boolean,
  selectedDate:string | Date,
  setTimeValue:string,
  options: any,
  selectedValue:any,
  getGoalsComplete:any,
  setValue:any,
  setCheckbox:boolean,
  SubtanceButtonCalled: boolean,
  userName:any,
  GoalAchievedPopup:any,
  dateErrorMessage:string,
  selectedSubstance: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}
interface NumberIndexedObject {
  [key: string]: string;
}

export default class GoalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGoalsHandleValue=""
  postCategoryKey=""
  fetchExistingSubstanceGoalApiCallId: string = "";
  putSubstanceGoalApiCallId: string = "";
  stillWorkingApiCallId: string = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isLoading: false,
      checkedItems:{},
      options: [], 
      newDateData: "",
      timeSet:'',
      currDate: {},
      formattedDateInter:undefined,
      valueOther:'',
      openCalender: "",
      openTime:false,
      editHandle:false,
      selectedDate:"",
      setTimeValue:'',
      selectedValue:'',
      getGoalsComplete:[],
      setValue:'',
      setCheckbox:false,
      SubtanceButtonCalled:false,
      userName:'',
      GoalAchievedPopup:false,
      dateErrorMessage:'',
      selectedSubstance: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handleGoals=this.handleGoals.bind(this)
  }
  async componentDidMount() {
    window.addEventListener("keydown", this.handleGoals);
    this.setState({ isLoading: true });
    let selectedTab= localStorage.getItem("selectedHeading")
    let selectedValue= localStorage.getItem("selectedSubValue")
    const selectedCardId = await getStorageData("selectedCardId");
    const newData = await getStorageData("newData");
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const firstNewData = await getStorageData("firstNewData");
    const selectedSubstance = await getStorageData("selectedSubstance");
    setTimeout(() => {
    this.scrollRef.current &&
      this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);

    setTimeout(()=>{
      this.handleDescriptionValue(selectedTab,selectedValue)
      this.setState({ isLoading: false });
    },1000)
    this.generateOptions();
    if((firstNewData && firstSelectedCardId) || (!newData && selectedCardId)) {
      this.fetchExistingSubstanceGoals();
    }
    this.setState({
      userName: localStorage.getItem("username"),
    }, () => {
      this.setState({
        selectedSubstance: selectedSubstance?.split(",") || [],
      })
    })
  }
  handleDescriptionValue(selectedTab:any,selectedValue:any){
     if(selectedTab==='Alcohol'){
      let value1=  localStorage.getItem("value1")
      let value2=  localStorage.getItem("value2")
      if(selectedValue==='Reduce how much'){
        this.setState({
          setValue: `Reduce your typical drinking amount from ${value1} to ${value2} drinks a day`
        });
      }
      else if(selectedValue==='Reduce how often'){
        this.setState({
          setValue: `Reduce your typical drinking days from  ${value1} to ${value2} days per week`
        });
      }
      else if(selectedValue==='Safer/Healthier'){
        this.setState({setValue:''})
      }
      else if(selectedValue==='Stop completely'){
        const doesMatchFirstFiveAlcohol = this.state.options
            .slice(0, 5)
            .some((opt: any) => opt.date === moment(value1).format("MM/DD/YYYY"));
      this.setState({ valueOther: doesMatchFirstFiveAlcohol ? "" : "other", formattedDateInter: moment(value1).format("MM/DD/YYYY"), selectedDate: moment(value1).format("MM/DD/YYYY") });
       this.setState({setValue:`Stop drinking completely from ${moment(value1).format("MMMM Do")}`})
      }
      else if(selectedValue==='Other'){
        this.setState({setValue:value1})
      }
     }
     else if(selectedTab==='Cannabis'||selectedTab==='Uppers'||selectedTab==='Downers'){
      let value4=  localStorage.getItem("value4")
      let value5=  localStorage.getItem("value5")
      let value6=  localStorage.getItem("value6")
        if(selectedValue==='Reduce how much'){
          this.setState({
            setValue: `Reduce your typical using quantity from ${value4} to ${value5} ${value6} a day`
          });
        }
        else if(selectedValue==='Reduce how often'){
          this.setState({
            setValue: `Reduce your typical using days from ${value4} to ${value5} days per week`
          });
        }
        else if(selectedValue==='Safer/Healthier'){
          this.setState({setValue:''})
        }
        else if(selectedValue==='Stop completely'){
          const doesMatchFirstFiveDowners = this.state.options
            .slice(0, 5)
            .some((opt: any) => opt.date === moment(value4).format("MM/DD/YYYY"));
          this.setState({ valueOther: doesMatchFirstFiveDowners ? "" : "other", formattedDateInter: moment(value4).format("MM/DD/YYYY"), selectedDate: moment(value4).format("MM/DD/YYYY") });
         this.setState({setValue:`Stop using completely from ${moment(value4).format("MMMM Do")}`})
        }
        else if(selectedValue==='Other'){
          this.setState({setValue:value4})
        }
       }
       else if(selectedTab=='Tobacco or Nicotine'){
        let staticValue =localStorage.getItem('staticData')
        let value7=  localStorage.getItem("value7")
        let value8=  localStorage.getItem("value8")
        let value10= localStorage.getItem("value10")
        let value11= localStorage.getItem("value11")
        let answer;
        if(value11==='Others'){
          answer=value10;
        }
        else{
          answer=value11;
        }

        if(selectedValue==='Reduce how much'){
            if(staticValue==='Yes'){
            
             this.setState({
              setValue:`Reduce frequency of using ${answer}`
            });
            }
            else{
              this.setState({
                setValue: `Reduce frequency of using ${answer} from ${value7} to ${value8} per day`
            })         
            }
          }
          else if(selectedValue==='Reduce how often'){
            this.setState({
              setValue: `Reduce frequency of using ${answer} from ${value7} to ${value8} days per week`
            });
          }
          else if(selectedValue==='Safer/Healthier'){
            this.setState({setValue:''})
          }
          else if(selectedValue==='Stop completely'){
            const doesMatchFirstFiveTobacco = this.state.options
            .slice(0, 5)
            .some((opt: any) => opt.date === moment(value7).format("MM/DD/YYYY"));
            this.setState({ valueOther: doesMatchFirstFiveTobacco ? "" : "other", formattedDateInter: moment(value7).format("MM/DD/YYYY"), selectedDate: moment(value7).format("MM/DD/YYYY") });
           this.setState({setValue:`Stop frequency of using ${answer} from ${moment(value7).format("MMMM Do")}`})
          }
          else if(selectedValue==='Other'){
            this.setState({setValue:value7})
          }
       }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
   
      if (this.getGoalsHandleValue === responseData && !responseJson.errors) {
        this.setState({
          getGoalsComplete: responseJson,
          setValue:  responseJson?.goals?.goal_description ,
          setCheckbox:responseJson?.goals?.daily_goal,
          formattedDateInter:responseJson?.goals?.target_date,
          timeSet:responseJson?.goals?.reminder_time,
          selectedDate: moment(responseJson?.goals?.target_date).format("MM/DD/YYYY"),
        });
      } else if (this.fetchExistingSubstanceGoalApiCallId === responseData && !responseJson.errors) {
        const doesMatchFirstFive = this.state.options
          .slice(0, 5)
          .some((opt: any) => opt.date === this.formatExistingDate(responseJson?.target_date));
        this.setState({
          setValue: this.state.setValue.length > 0 ? this.state.setValue : responseJson.goal_description,
          setCheckbox: responseJson?.daily_goal,
          formattedDateInter: this.formatExistingDate(responseJson?.target_date),
          timeSet: responseJson?.reminder_time,
          selectedDate: moment(responseJson?.target_date).format("MM/DD/YYYY"),
          valueOther: !doesMatchFirstFive ? "other" : "",
        });
      } else if (this.putSubstanceGoalApiCallId === responseData && !responseJson.errors) {
        if(responseJson.message === "Substance related goal updated successfully") {
          const stillWorking = await getStorageData("stillWorking");
          const selectedCardId = await getStorageData("firstSelectedCardId");
          this.setState({
            setValue: responseJson?.data?.goal_description,
            setCheckbox: responseJson?.data?.daily_goal,
            formattedDateInter: responseJson?.data?.target_date,
            timeSet: responseJson?.data?.reminder_time,
            selectedDate: moment(responseJson?.data?.target_date).format("MM/DD/YYYY"),
          });
          if(stillWorking === "true") {
            const header = {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            };
            const substanceMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            this.stillWorkingApiCallId = substanceMessage.messageId;
            substanceMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `bx_block_custom_forms/substance_goal_responses/goal_still_working?id=${selectedCardId}`
            );
            substanceMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
            substanceMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              "GET"
            );
            runEngine.sendMessage(substanceMessage.id, substanceMessage);
          }
        }
        const newUseFound =localStorage.getItem("NewUseFound")
        const selectedCardId = await getStorageData("selectedCardId");
        const newData = await getStorageData("newData");
        const firstSelectedCardId = await getStorageData("firstSelectedCardId");
        const firstNewData = await getStorageData("firstNewData");
        const stillWorking = await getStorageData("stillWorking");
        const checkNewData = (!firstNewData && firstSelectedCardId) || (!newData && selectedCardId);
        if(!firstNewData && !firstSelectedCardId) {
        if( localStorage.getItem("NewUseFound")!='Yes' && responseJson.message === "Substance related goal updated successfully"){
          this.setState({
            GoalAchievedPopup: true});
        }
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "GoalsInformation");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
        } else {
          if(stillWorking === "true") {
            setStorageData("weekValue", "0");
          }
          const msg = new Message(getName(MessageEnum.NavigationMessage));
          msg.addData(getName(MessageEnum.NavigationTargetMessage), "GoalsInformation");
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
        }
        const answer1=localStorage.getItem("valuesSequence")
        const answer2=localStorage.getItem("currentValue")
        if (answer1 && answer2) {
          const ansArray = answer1?.split(","); 
          const index = ansArray?.indexOf(answer2); 
          if (index !== -1 && index + 1 < ansArray.length) {
            const nextSubstance = ansArray[index + 1]; 
            localStorage.setItem("selectedTab",nextSubstance)
            localStorage.setItem("selectedHeading",nextSubstance)
            if(!checkNewData) {
              this.props.navigation.navigate("CustomForm");
            }
          } else {
              if(!checkNewData && newUseFound !== 'Yes' ) {
                const msg = new Message(getName(MessageEnum.NavigationMessage));
                msg.addData(getName(MessageEnum.NavigationTargetMessage), "GoalsInformation");
                msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(msg);
              }
              else if(newUseFound === 'Yes' && !checkNewData){
                this.props.navigation.navigate("SaferUserStrategies");
              }
          }
        }
      }
    };    
  }

  // Customizable Area Start
  handleGoals(event: { key: string }) {
    const elements = ["modal-goals", "modal-others"];
    let directionAns = 0;

    if (event.key === "ArrowDown") {
      directionAns = 100;
    } else if (event.key === "ArrowUp") {
      directionAns = -100;
    }

  
    if (directionAns !== 0) {
      elements.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollBy({ top: directionAns, behavior: "smooth" });
        }
      });
    }
  }
  
  async componentWillUnmount() {
    window.removeEventListener("keydown", this.handleGoals);
  }
  fetchExistingSubstanceGoals = async () => {
    const selectedCardId = await getStorageData("selectedCardId");
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchExistingSubstanceGoalApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/substance_related_goals/${selectedCardId || firstSelectedCardId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async introductionPage(){
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const newUseFound = localStorage.getItem("NewUseFound");
    const redirect = newUseFound === "Yes" && firstSelectedCardId ? "SubstanceGoals" : "Categoriessubcategories";
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  OtherGoals(){
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    removeStorageData("otherGoalId");
     this.props.navigation.navigate("OtherGoals")
  }
  hamburgerMenu(){
    localStorage.setItem("pageNavigate","Goals")
    this.props.navigation.navigate("HamburgerMenu")
  }
 
  SubstanceGoalsTohamburgerMenu(){
    localStorage.setItem("pageNavigate","SubstanceGoals")
    this.props.navigation.navigate("HamburgerMenu")
  }
  formatDate(date:any) {
    const dateMoment = moment(date); 
    const formattedDate = dateMoment?.format('ddd, MMMM D'); 
    const isToday = dateMoment?.isSame(moment(), 'day'); 
    return isToday ? formattedDate?.replace(',', '') : formattedDate;
}
formatDateChange = (dateStr: any) => {
  if (!dateStr) {
    return ''; 
  }
  const [month, day, year] = dateStr.split('/');
  if (!year || !day || !month) {
    return ''; 
  }
  return `${month}-${day}-${year}`; 
};

formatExistingDate = (dateStr: any) => {
  if (!dateStr) {
    return ''; 
  }
  const [month, day, year] = dateStr.split('-');
  if (!year || !day || !month) {
    return ''; 
  }
  return `${month}/${day}/${year}`; 
};
  GoalsSaveHandle = async () => {
    let data = localStorage.getItem("token") || "{}";
    const selectedCardId = await getStorageData("selectedCardId");
    const newData = await getStorageData("newData");
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const firstNewData = await getStorageData("firstNewData");
    const checkNewData = (firstNewData && firstSelectedCardId) || (!newData && selectedCardId);
      /* istanbul ignore next */
    let answerfinal=localStorage.getItem("idSend")
    const dataSend = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data
    };
    const httpBody = {
      substance_related_goal: {
        goal_description: this.state.setValue,
        target_date: this.formatDateChange(this.state.formattedDateInter),
        reminder_time: this.state.timeSet ,
        daily_goal:this.state.setCheckbox,
        substance_goal_response_id: checkNewData ? Number(selectedCardId) || Number(firstSelectedCardId) : Number(answerfinal) || null,
      }
    };
    if(checkNewData) {
      const getCategory = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.putSubstanceGoalApiCallId = getCategory.messageId;
      getCategory.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_custom_forms/substance_related_goals/${selectedCardId || firstSelectedCardId}`
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        dataSend
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(getCategory.id, getCategory);
    } else {
      const getCategory = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postCategoryKey = getCategory.messageId;
      getCategory.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.goalsEndPoint}`
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        dataSend
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addUserApiMethod
      );
      getCategory.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(getCategory.id, getCategory);
      this.setState({
        GoalAchievedPopup:true
      });
    }
    setStorageData("firstSelectedCardId", selectedCardId);
    setStorageData("firstNewData", true);
    removeStorageData("selectedCardId");
    removeStorageData("newData");
  }
  GoalsGet = () => {
  let data = localStorage.getItem("token") || "{}";
  const header = {
    "Content-Type": configJSON.getUserListApiContentType,
    token: data
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getGoalsHandleValue = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.goalsEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getUserListApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  };


generateOptions() {
  const today = new Date();
  const formattedOptions = [
      {
          value: 'today',
          label: `Today, ${this.formatDate(today)}`,
          date: this.formatDateYYYYMMDD(today),
      },
  ];
  for(let i = 1; i <= 4; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      formattedOptions.push({
          value: `nextDay${i}`,
          label: `${this.formatDate(nextDate)}`,
          date: this.formatDateYYYYMMDD(nextDate),
      });
  }
  formattedOptions.push({ value: 'other', label: 'Other Day', date: '' });
  this.setState({ options: formattedOptions });
}

formatDateYYYYMMDD(date:any) {
  if (!date){
    return;
  }
  const year = new Date(date).getFullYear();
  const month = String(new Date(date).getMonth() + 1).padStart(2, '0'); 
  const day = String(new Date(date).getDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
}
  handleInputChange = async (event: any) => {
    const value = event?.target?.value;
    this.setState({ setValue: value })
  };

  handleChange = (event:any) => {
      this.setState({ formattedDateInter: moment(event.date).format("MM/DD/YYYY"), selectedDate: "" ,SubtanceButtonCalled: false, valueOther: ""});
};
goalsPage(){
  const msg = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}

openPopUp(key: number) {
  this.setState({
    openCalender: key.toString(),
    dateErrorMessage: "",
  });
}
openTimer() {
  this.setState({
    openTime: true
  });
}
handleEdit(){
  this.setState({editHandle:true})
}
closePopUp() {
  this.setState({
    openCalender: ""
  });
}

  handleCheckboxChange = () => {
    if(this.state.setCheckbox){
      this.setState({setCheckbox:false})
    }
    else{
      this.setState({setCheckbox:true})
    }
  }
  substancePage(){
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    const valuesSequence = localStorage.getItem("valuesSequence");
    localStorage.setItem("selectedTab", valuesSequence?.split(",")?.[0] || "");
    localStorage.setItem("selectedHeading", valuesSequence?.split(",")?.[0] || "");
    this.props.navigation.navigate("CustomForm")
  }
  convertToDayjsDate(dateString:string) {
    if (!dateString) return null; 
    return dayjs(dateString, 'DD/MM/YYYY'); 
  }
  handleDate(e: any) {
    const date = dayjs.isDayjs(e) ? e : dayjs(e);
    if (date.isValid()) {
      let finalDate = date.format("DD/MM/YYYY"); 
      this.setState({
        newDateData: finalDate
      });
    } 
  }
  handleTime(e:any){
    this.setState({
      timeSet: e,
      openTime: false
  });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  handlePopupTime(keyAnswer: any) {
    if (keyAnswer) {return true;} else {
      return false;
    }
  }
  async customForm(){
    const selectedCardId = await getStorageData("selectedCardId");
    if(!selectedCardId) {
      this.setState({
        SubtanceButtonCalled:true
      })
    }
    if(!(((this.state.formattedDateInter === undefined || this.state.formattedDateInter?.length < 3)&& this.state.formattedDateInter !== '') || this.state.formattedDateInter === "Invalid date") && this.state.setValue.trim()?.length >= 1 && (this.state.timeSet !== undefined && this.state.timeSet?.length > 1) ){
   this.GoalsSaveHandle();
    }
    else{
      this.setState({
        SubtanceButtonCalled:true
      })
    }
    }
  confirmCal() {
    
    const selectedDate = this.state.selectedDate; 
    const currentDate = dayjs(); 
    const doesMatchExistingDate = this.state.options
      .slice(0, 5)
      .some((opt: any) => opt.date === selectedDate);
  
    if (selectedDate === "") {
      this.setState({
        dateErrorMessage: "Date is required.",
      });
      return;
    }
    if (dayjs(selectedDate).isBefore(currentDate, "day")) {
      this.setState({
        dateErrorMessage: "Please select a valid date that is today or in the future.",
      });
      return; 
    }
  
    this.setState({
      formattedDateInter: selectedDate.toString(),
      openCalender: "",
      dateErrorMessage: "",
      valueOther: doesMatchExistingDate ? "" : "other",
    });
  }
  handleDataCalendar=(newDate:any)=>{
    const formattedDate = dayjs(newDate.$d).format('MM/DD/YYYY');
    this.setState({selectedDate:formattedDate})
  }
  handleDataChangeTime = (newDate: any) => {
    const formattedDate = dayjs(newDate).format('hh:mm A');
    this.setState({ setTimeValue: formattedDate });
  };
  handleUserTime=()=>{
    this.setState({timeSet:this.state.setTimeValue})
    this.setState({openTime:false})
  }
  handleClose=()=>{
    this.setState({openTime:false})
  }

  MoveToDashboard = async () => {
    if(  localStorage.getItem("dashboardUser") !== "true"){
     return this.props.navigation.navigate("DashboardInformation")
    }
    const NewUseFound =localStorage.getItem("NewUseFound")
    const selectedCardId = await getStorageData("selectedCardId");
    const newData = await getStorageData("newData");
    const firstSelectedCardId = await getStorageData("firstSelectedCardId");
    const firstNewData = await getStorageData("firstNewData");
    const checkNewData = (firstNewData && firstSelectedCardId) || (!newData && selectedCardId);
    const answer=localStorage.getItem("valuesSequence")
    const answer2=localStorage.getItem("currentValue")
    if (answer && answer2) {
      const answerArray = answer?.split(","); 
      const currentIndex = answerArray?.indexOf(answer2);
      if (currentIndex !== -1 && currentIndex + 1 < answerArray.length) {
        const nextValue = answerArray[currentIndex + 1]; 
        localStorage.setItem("selectedTab",nextValue)
        localStorage.setItem("selectedHeading",nextValue)
        if(checkNewData) {
          const msg = new Message(getName(MessageEnum.NavigationMessage));
          msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomForm");
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(msg);
        }
      } else {
        const redirectUrl = NewUseFound === "Yes" ? "SaferUserStrategies" : "DashboardInformation";
        /* istanbul ignore next */
        if( NewUseFound==='Yes'){
          localStorage.setItem("valueMe","SubstanceGoals")
          }
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), redirectUrl);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
    }
  }
  handleEditDiv = () => {
      document.addEventListener('mousedown', this.handleOutside)
  };
  handleOutside = (event: any) => {
    if (!event.target.closest('.popup-container')) {
        this.setState({editHandle:false})
    document.removeEventListener('mousedown', this.handleOutside);  
    }
  };
  handleAddMoreGoal = () => {
    this.setState({ GoalAchievedPopup: false }, () => {
      const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
      redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Goals");
      redirectMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(redirectMsg);
    })
  };
  // Customizable Area End
}
