import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import createRequest from "./helpers/create-request";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { alcoholData, cannabis, downers, tobaccoOrNicotine, uppers } from "./assets";
// Customizable Area Start
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  valueCheck:string,
  checkedItems: any,
  checkedIte: any,
  gridItems: any,
  headerItems: any,
  scrollOffset: number,
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet: any,
  openCalender: string,
  openTime: boolean
  selectedDate: string,
  options: any,
  selectedValue: any,
  activeTab: any,
  currentIndex: {
    firstRow: number,
    secondRow: number,
    thirdRow: number
  },
  selectedCategories: any[]
  isCardSelected: boolean,
  categoryNameStore: any[],
  token: string,
  selectedCardTitle : any[],
  selectedCardId: any[],
  userName:any,
  itemsPerPage: any
  GoalAchievedPopup:any,
  currentSelectedCategory: string;
  selectedCategory: {}[];
  selectedCategoryId: string;
  selectedSUS: {}[];
  selectedCard: string;
  errorMsg: string;
  addMoreCount: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface NumberIndexedObject { [key: string]: string; }

export default class SaferUserStrategiesController extends BlockComponent< Props, S, SS > {
  // Customizable Area Start
  getSelectedCategoris: string = "";
  deleteSelectedCategoris: string = "";
  addSelectedCategoris: string = "";
  getCategoryValueExtrat: string = "";
  postValueId:string =""
  fetchExistingStrategyApiCallId: string = "";
  postSUSCategoryApiCallId: string = "";
  putSUSCategoryApiCallId: string = "";
  scrollRef: any = createRef();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start      
      isLoading: false,
      valueCheck:'',
      scrollOffset: 0,
      gridItems: [{
        firstRow: [],
        secondRow: [],
        thirdRow: []
      }],
      headerItems: '',
      checkedItems: {},
      checkedIte: {},
      options: [],
      newDateData: "",
      timeSet: '',
      currDate: {},
      openCalender: "",
      openTime: false,
      selectedDate: "",
      selectedValue: '',
      activeTab: '',
      currentIndex: {
        firstRow: 0,
        secondRow: 0,
        thirdRow: 0
      },
      selectedCategories: [],
      isCardSelected: false,
      categoryNameStore: [],
      token: '',
      selectedCardTitle: [],
      selectedCardId: [],
      userName:'',
      itemsPerPage: {
        firstRow: 0,
        secondRow: 0,
        thirdRow: 0
      },
      GoalAchievedPopup:false,
      currentSelectedCategory: "",
      selectedCategory: [],
      selectedCategoryId: "",
      selectedSUS: [],
      selectedCard: "",
      errorMsg: "",
      addMoreCount: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handleSaferKeyDown=this.handleSaferKeyDown.bind(this)
    this.goBackToDashboard = this.goBackToDashboard.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.goBackToSubstanceGoal = this.goBackToSubstanceGoal.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.selectCardHandler = this.selectCardHandler.bind(this);
    this.handleSetTokenWeb = this.handleSetTokenWeb.bind(this)

    // Customizable Area End
  }

  throttle(fn:any, wait:any) {
    let isCalled = false;
    return function(...args: any) {
      if (!isCalled) {
        fn(...args);
        isCalled = true;
        setTimeout(() => {
          isCalled = false;
        }, wait);
      }
    };
  }

  throttledResize = this.throttle(() => {
    this.handleResize();
  }, 100); 

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const valueCheck = await getStorageData("BackToSaferuseStrategies");
    const activeTab = await getStorageData("activeTab");
    this.setState({
      userName: localStorage.getItem("username"),
      isLoading: true,
      valueCheck,
      activeTab,
    })
    setTimeout(() => {
      this.handleResize();
    }, 0);
    const authToken = await getStorageData("token", false);
    this.handleSetTokenWeb(authToken);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryValueExtrat = requestMessage.messageId;

    let tokenHandle = localStorage.getItem("token") || "{}";
    const selectedCardId = await getStorageData("selectedCardId");
    if(!selectedCardId) {
      createRequest({
        requestMsg: requestMessage,
        endPoint: `${configJSON.categoryShowPoint}`,
        method: configJSON.getApiMethodType,
        token: tokenHandle
      });
    } else {
      this.fetchExistingStrategyData(selectedCardId);
    }
    setTimeout(() => {
    this.scrollRef.current &&
      this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    window.addEventListener("keydown", this.handleSaferKeyDown);
    window.addEventListener('resize', this.throttledResize);
    // Customizable Area End
  }

  handleSetTokenWeb(authToken: string) {
    if (authToken) {
      this.setState({
        token: authToken,
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiResponseWeb = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseWebJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponseWeb === this.getSelectedCategoris && responseWebJson) {
        this.getSelectedCategoriesHandler(responseWebJson)
      }
      if (apiResponseWeb === this.addSelectedCategoris && responseWebJson) {
        this.addSelectedCategoriesHandler()
      }
      if (apiResponseWeb === this.deleteSelectedCategoris && responseWebJson) {
        this.deletehandler()
      }
      if (apiResponseWeb === this.getCategoryValueExtrat) {
        this.handleCategory(responseWebJson)
      }
      if (apiResponseWeb === this.fetchExistingStrategyApiCallId) {
        this.handleExistingData(responseWebJson);
      }
      if(apiResponseWeb === this.postSUSCategoryApiCallId || apiResponseWeb === this.putSUSCategoryApiCallId) {
        if(responseWebJson) {
          this.setState(prevState => {
            return {
              isLoading: false,
              selectedCategory: [...prevState.selectedCategory, {[responseWebJson?.id]: responseWebJson?.title}]
            }
          });
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("keydown", this.handleSaferKeyDown);
  }
  handleSaferKeyDown(event: { key: string }) {
    const modalContent = document.getElementById("modal-safer");
  
   
      let direction = 0;

      if (event.key === "ArrowDown") {
        direction = 100;
      } else if (event.key === "ArrowUp") {
        direction = -100;
      }

      if (direction !== 0 && modalContent) {
        modalContent.scrollBy({ top: direction, behavior: "smooth" });
    }
  }
  
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(
      this.state.selectedCategory !== prevState.selectedCategory
    ) {
      const selectedCategory: any = this.state.selectedCategory.find(item => Object.values(item)[0] === this.state.currentSelectedCategory)
      this.setState({
        selectedCategoryId: Object.keys(selectedCategory)?.[0]
      })
    }
  }
  fetchExistingStrategyData = (selectedCardId: number) => {
    this.setState({ isLoading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.fetchExistingStrategyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/safer_user_strategies/${selectedCardId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResize = () => {
    const containerWidth = window.innerWidth;
    let visibleItemCount = 3;
    
    if (containerWidth < 600) {
      visibleItemCount = 1;
    } else if (containerWidth >= 600 && containerWidth < 1000) {
      visibleItemCount = 2;
    } else if (containerWidth >= 1000) {
      visibleItemCount = 3;
    }

    this.setState({
      itemsPerPage: {
        ...this.state.itemsPerPage,
      firstRow: visibleItemCount > 0 ? visibleItemCount : 1,
      secondRow: visibleItemCount > 0 ? visibleItemCount : 1,
      thirdRow: visibleItemCount > 0 ? visibleItemCount : 1
      }
    });
  };

  handleTabChange = async (event: React.SyntheticEvent | null, newValue: string) => {
    this.setState({ errorMsg: "" });
    const selectedCardId = await getStorageData("selectedCardId");
    setStorageData("activeTab", newValue);
    this.setState({
      activeTab: newValue,
      currentIndex: {
        firstRow: 0,
        secondRow: 0,
        thirdRow: 0,
      },
    }, () => {
      let gridItems = {};
      let headerItems = {};
      let isCardSelected = false;

      if (newValue === 'Alcohol') {
        gridItems = alcoholData;
        headerItems = configJSON.alcoholHead;
        isCardSelected = true;
      } else if (newValue === 'Cannabis') {
        gridItems = cannabis;
        headerItems = configJSON.cannabisHead;
      } else if (newValue === 'Tobacco or Nicotine') {
        gridItems = tobaccoOrNicotine;
        headerItems = configJSON.tobaccoHead;
        isCardSelected = true;
      } else if (newValue === 'Uppers') {
        gridItems = uppers;
        headerItems = configJSON.upperHead;
        isCardSelected = true;
      } else if (newValue === 'Downers') {
        gridItems = downers;
        headerItems = configJSON.upperHead;
        isCardSelected = true;
      } else {
        gridItems = { firstRow: [], secondRow: [] };
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getSelectedCategoris = requestMessage.messageId;
      if(!selectedCardId) {
        createRequest({
          requestMsg: requestMessage,
          endPoint: `${configJSON.saferUserStrategiesEndPoint}?category=${newValue}`,
          method: configJSON.getApiMethodType,
          token: this.state.token
        });
      }

      this.setState({
        gridItems: gridItems,
        headerItems: headerItems,
        isCardSelected: isCardSelected,
      });
    });
  };

  goBackToDashboard = async () => {
    const newUseFound = localStorage.getItem("NewUseFound");
    const routePath = "SaferuseStrategies";
    let navigationTarget;

    if (newUseFound === 'Yes') {
      if (localStorage.getItem("valueMe") === 'OtherLifeGoals') {
        navigationTarget = 'OtherGoals';
      } else {
        navigationTarget = 'SubstanceGoals';
      }
    } else {
      navigationTarget = routePath;
    }

const msg = new Message(getName(MessageEnum.NavigationMessage));
msg.addData(getName(MessageEnum.NavigationTargetMessage), navigationTarget);
msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(msg);
  }

  goBackToSubstanceGoal = async () => {
    const selectedCardId = await getStorageData("selectedCardId");
    const { activeTab, categoryNameStore, selectedSUS, selectedCard, selectedCardTitle, selectedCategories, gridItems } = this.state;

    if(selectedCategories.length !== 0 && Object.values(gridItems).flat().length === selectedCategories.length) {
      this.setState({
        errorMsg: "* All safer user strategies have been created. Please wait for one week to create new safer user strategies."
      });
      return;
    }
    if(
      (!selectedCardId && !categoryNameStore.filter(key => selectedSUS.find(item => Object.keys(item).includes(key))).includes(activeTab))
      ||
       (selectedCardId && selectedCardTitle?.length <= 1 && selectedCard?.length <= 1 )
    ) {
      this.setState({
        errorMsg: "* Please select atleast one safer user strategies."
      });
      return;
    } else {
      this.setState({
        errorMsg: ""
      });
    }
    if(selectedCardId) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "SaferuseStrategies");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      removeStorageData("selectedCardId");
      return;
    }
    this.setState(prevState => {
      return { addMoreCount: prevState.addMoreCount + 1};
    }, () => {
      this.setState({ GoalAchievedPopup: true });
    });
  }

  handleNext = (items: Array<any>, row: 'firstRow' | 'secondRow' | 'thirdRow') => {    
    this.setState(prevState => ({
      currentIndex: {
        ...prevState.currentIndex,
        [row]: Math.min(prevState.currentIndex[row] + prevState.itemsPerPage.firstRow, this?.state?.gridItems[row]?.length - prevState.itemsPerPage.firstRow)
      }
    }));
  };

  handlePrevious = (items: Array<any>, row: 'firstRow' | 'secondRow' | 'thirdRow'): void => {
    this.setState(prevState => ({
      currentIndex: {
        ...prevState.currentIndex,
        [row]: Math.max(prevState.currentIndex[row] - prevState.itemsPerPage.firstRow, 0)
      }
    }));
  };

  postAPICall = (selectedId: any, formData: any) => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({selectedCardTitle: [...this.state.selectedCardTitle, selectedId]})
    this.setState({ isLoading: true });
    this.postSUSCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saferUserStrategiesEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), 
      configJSON.postApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  putAPICall = (selectedId: any, formData: any) => {
    const putHeader = {
      token: this.state.token
    };
    const putMsgCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({selectedCardTitle: [...this.state.selectedCardTitle, selectedId]})
    this.setState({ isLoading: true });
    this.putSUSCategoryApiCallId = putMsgCall.messageId;
    putMsgCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_forms/safer_user_strategies/${selectedId}`
    );
    putMsgCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify(putHeader)
    );
    putMsgCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), 
      "PUT"
    );
    putMsgCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(putMsgCall.id, putMsgCall);
  };

  selectCardHandler = async (items: any, category: string, isSelectedCard: boolean) => {
    if(isSelectedCard) {
      return;
    }
    this.setState((prevState) => {
      const existingCategory: any = prevState.selectedSUS.find((item: any) => item[category]);
      if (existingCategory) {
        const titleIndex = existingCategory[category].indexOf(items?.title);
        if (titleIndex > -1) {
          existingCategory[category].splice(titleIndex, 1);
          if (existingCategory[category].length === 0) {
            return {
              selectedSUS: prevState.selectedSUS.filter((item) => item !== existingCategory),
              errorMsg: "",
            };
          }
          return { selectedSUS: [...prevState.selectedSUS], errorMsg: "" };
        } else {
          existingCategory[category].push(items?.title);
          return { selectedSUS: [...prevState.selectedSUS], errorMsg: "" };
        }
      } else {
        return {
          errorMsg: "",
          selectedSUS: [...prevState.selectedSUS, { [category]: [items?.title] }]
        };
      }
    });
    
    this.setState({ currentSelectedCategory : items?.title, selectedCard: "" });
    const selectedCardId = await getStorageData("selectedCardId");
    const isDelete = this.state.selectedCardTitle?.includes(items.id)

    function base64ToBlob(base64Data:any, contentType = "image/png") {
      const base64 = base64Data.replace(/^data:image\/(png|jpeg);base64,/, ""); 
      const byteCharacters = atob(base64); 
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    }
    const icon = base64ToBlob(items.img.default);
    const formData = new FormData()
    formData.append("safer_user_strategy[category]",category )
    formData.append("safer_user_strategy[title]",items.title )
    formData.append("safer_user_strategy[icon]",icon )
    if(selectedCardId) {
        const filterItem = this.state.selectedCardTitle?.filter((item) => item !== items.id)
        this.setState({selectedCardTitle: filterItem});
        const selectedCategory = this.state.selectedCategory.filter(item => Object.keys(item)[0] !== this.state.selectedCategoryId);
        this.setState({ selectedCategory, selectedCardTitle: [items.id] });
        this.putAPICall(selectedCardId, formData);
    } else {
      if (isDelete) {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const filterItem = this.state.selectedCardTitle?.filter((item) => item !== items.id)
        this.setState({selectedCardTitle: filterItem})
        createRequest({
          requestMsg: requestMessage,
          endPoint: `${configJSON.saferUserStrategiesEndPoint}/${this.state.selectedCategoryId}`,
          method: configJSON.deleteApiMethodType,
          token: this.state.token
        });
        const selectedCategory = this.state.selectedCategory.filter(item => Object.keys(item)[0] !== this.state.selectedCategoryId);
        this.setState({ selectedCategory });
      } else {
        this.postAPICall(items.id, formData);
      }
    }
  }

  getSelectedCategoriesHandler = (responseWebJson: any) => {
    if(responseWebJson && responseWebJson.length > 0) {
      const selectedCategory = responseWebJson.map((category: any) => {
        return {[category.id]: category.title};
      });
      const selectedCardTitle = responseWebJson.map((category: any) => {
        return category.id
      });
      this.setState(prevState => {return { selectedCategories: responseWebJson, selectedCategory, selectedCardTitle }});
    }
  }

  addSelectedCategoriesHandler = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    createRequest({
      requestMsg: requestMessage,
      endPoint: `${configJSON.saferUserStrategiesEndPoint}?category=${this.state.activeTab}`,
      method: configJSON.getApiMethodType,
      token: this.state.token
    });
  }

  deletehandler = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    createRequest({
      requestMsg: requestMessage,
      endPoint: `${configJSON.saferUserStrategiesEndPoint}?category=${this.state.activeTab}`,
      method: configJSON.getApiMethodType,
      token: this.state.token
    });
  }

  hamburgerMenu = () => {
    localStorage.setItem("pageNavigate","SaferUserStrategies")
    this.props.navigation.navigate("HamburgerMenu")
  }

  handleCategory = async (responseJson: any) => {
    const activeTab1 = await getStorageData("activeTab");
    const categoryArray = ["Alcohol", "Cannabis", "Tobacco or Nicotine", "Uppers", "Downers"];
    const categoryNames = responseJson?.categories?.map((category: any) => category?.category_name);
    const matchedCategory = categoryArray.find(category => categoryNames?.includes(category));
    if(!activeTab1) {
      this.setState({ activeTab: matchedCategory }, () => setStorageData("activeTab", this.state.activeTab));
    }
    const activeTab = await getStorageData("activeTab");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSelectedCategoris = requestMessage.messageId;

    createRequest({
      requestMsg: requestMessage,
      endPoint: `${configJSON.saferUserStrategiesEndPoint}?category=${activeTab ? activeTab : categoryNames[0]}`,
      method: configJSON.getApiMethodType,
      token: this.state.token
    });

    let gridItems = {};
    let headerItems = {};
    let isCardSelected = false;

    if (activeTab === "Alcohol" && categoryNames.includes("Alcohol")) {
      gridItems = alcoholData;
      headerItems = configJSON.alcoholHead;
      isCardSelected = true;
    } else if (activeTab === "Cannabis" && categoryNames.includes('Cannabis')) {
      gridItems = cannabis;
      headerItems = configJSON.cannabisHead;
    } else if (activeTab === "Tobacco or Nicotine" && categoryNames.includes("Tobacco or Nicotine")) {
      gridItems = tobaccoOrNicotine;
      headerItems = configJSON.tobaccoHead;
      isCardSelected = true;
    } else if (activeTab === "Uppers" && categoryNames.includes('Uppers')) {
      gridItems = uppers;
      headerItems = configJSON.upperHead;
      isCardSelected = true;
    } else if (activeTab === "Downers" && categoryNames.includes('Downers')) {
      gridItems = downers;
      headerItems = configJSON.upperHead;
      isCardSelected = true;
    } else {
      gridItems = { firstRow: [], secondRow: [] };
    }

    this.setState({
      gridItems: gridItems,
      headerItems: headerItems,
      isCardSelected: isCardSelected,
      isLoading: false,
    });

    const predefinedSequence = ['Alcohol', 'Cannabis', 'Tobacco or Nicotine', 'Uppers', 'Downers', 'Others'];
    const firstMatchingCategory = predefinedSequence.find(category => categoryNames?.includes(category));
    const sortedCategoryNames = categoryNames?.sort((a: any, b: any) => {
      return predefinedSequence?.indexOf(a) - predefinedSequence?.indexOf(b);})
      this.setState({ categoryNameStore: sortedCategoryNames });
      if(activeTab) {
        this.setState({ activeTab });
      } else {
        setStorageData("activeTab", firstMatchingCategory);
        this.setState({ activeTab: firstMatchingCategory });
      }
  };

  handleExistingData = (responseJson: any) => {
    if(responseJson) {
      this.setState({ categoryNameStore: [responseJson.category], isLoading: false, selectedCard: responseJson.title });
      this.handleTabChange(null, responseJson.category);
    }
  };

  MoveToDashboard = async () => {
    const selectedCardId = await getStorageData("selectedCardId");
    const NewUseFound = localStorage.getItem("NewUseFound");
    const currentIndex = this.state.categoryNameStore.indexOf(this.state.activeTab)
    const nextTab = currentIndex >= 0 && currentIndex < this.state.categoryNameStore.length - 1 
        ? this.state.categoryNameStore[currentIndex + 1]
        : '';
    if(!selectedCardId) {
      if(nextTab) {
        this.setState({ GoalAchievedPopup: false, addMoreCount: 0 }, () => {
          if(NewUseFound !== "Yes") {
            const oldUserRoute = new Message(getName(MessageEnum.NavigationMessage));
            oldUserRoute.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardInformation");
            oldUserRoute.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(oldUserRoute);
          } else {
          this.handleTabChange(null, nextTab)
          setStorageData("activeTab", nextTab);
          this.setState({ activeTab: nextTab });
          }
          setTimeout(() => {
          this.scrollRef.current &&
            this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
          }, 0);
        });
      } else {
        const newUserMsg = new Message(getName(MessageEnum.NavigationMessage));
        newUserMsg.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardInformation");
        newUserMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(newUserMsg);
      }
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "SaferuseStrategies");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  handleAddMoreStrategy = () => {
    this.setState({ GoalAchievedPopup: false }, () => {
      setTimeout(() => {
      this.scrollRef.current &&
        this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
      const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
      redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SaferUserStrategies");
      redirectMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(redirectMsg);
    })
  };
  // Customizable Area End
}